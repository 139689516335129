.category-section {
  margin-bottom: 4.8rem;

  .category-box {
    display: grid;
    background-color: #fff;
    color: #444;
    margin-bottom: 3.2rem;
    grid-template-columns: 50% 50%;
    grid-template-rows: 42rem 42rem;
    .category-box-child {
      position: relative;

      h4 {
        position: absolute;
        top: 4.4rem;
        left: 4.4rem;
        font-style: normal;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 140%;
        
        
        color: #000000;
      }

      img {
        position: absolute;
        right: 0;
        bottom: 0;
        width: auto;
        height: 30rem;
        object-fit: contain;
      }

      &:nth-child(1) {
        // grid-column: 1;
        // grid-row: 1 / span 2;
        background-color: #fce367;
        // h4 {
        //   right: 0;
        //   margin: auto;
        //   width: 70%;
        // }
        img {
          object-fit: cover;
          // height: 28.4rem;
        }
      }

      &:nth-child(2) {
        // grid-column: 2 / span 2;
        background: #82bebe;

        img {
          right: 3.2rem;
          // height: 28.4rem;
        }
      }

      &:nth-child(3) {
        // grid-column: 2;
        // grid-row: 2;
        background: #e6e8e3;

        img {
          right: 2.4rem;
          // height: 23.4rem;
        }

        h4 {
          width: 50%;
        }
      }

      &:nth-child(4) {
        // grid-column: 3;
        // grid-row: 2;
        background: #fdd9e3;

        img {
          right: 2.4rem;
          // height: 23.4rem !important;
        }

        h4 {
          width: 50%;
        }
      }
    }
  }

  .category-box-child {
    overflow: hidden;
  }

  .category-box-child img {
    transition: transform 0.5s ease;
  }

  .category-box-child:hover img {
    transform: scale(1.1);
  }

  @media only screen and (max-width: 767.9px) {
    .category-box {
      display: flex;
      flex-direction: column;

      .category-box-child {
        height: 30rem !important;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        overflow: hidden;

        h4 {
          font-size: 2.4rem;
          line-height: 140%;
          // position: static;
          left: 3rem;
          top: 4rem;
          right: auto !important;
          width: 48% !important;
          font-weight: 600;
        }

        img {
          width: 50%;
          height: 20rem !important;
          right: 0 !important;
          bottom: 0;
          object-fit: contain !important;
        }
      }
    }
  }
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}

.App {
  position: relative;
  background-color: #fafafa;
}

.body-overflow {
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input:focus::placeholder {
  color: transparent !important;
}

* {
  font-family: "Montserrat", sans-serif !important;
}

.container {
  padding: 0 1.6rem;
  max-width: 126.4rem;
  margin: 0 auto;
}

.container-second {
  padding: 0 1.6rem;
  max-width: 130.8rem;
  margin: 0 auto;
}

@media only screen and (max-width: 1199.9px) {
  html {
    font-size: 9px;
  }
}

@media only screen and (max-width: 1023.9px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 991.9px) {
  html {
    font-size: 7px;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 1.6rem;
}

@media only screen and (min-width: 1199.9px) {
  .xl-1 {
    grid-column: span 1;
  }

  .xl-2 {
    grid-column: span 2;
  }

  .xl-3 {
    grid-column: span 3;
  }

  .xl-4 {
    grid-column: span 4;
  }

  .xl-5 {
    grid-column: span 5;
  }

  .xl-6 {
    grid-column: span 6;
  }

  .xl-7 {
    grid-column: span 7;
  }

  .xl-8 {
    grid-column: span 8;
  }

  .xl-9 {
    grid-column: span 9;
  }

  .xl-10 {
    grid-column: span 10;
  }

  .xl-11 {
    grid-column: span 11;
  }

  .xl-12 {
    grid-column: span 12;
  }
}

@media only screen and (max-width: 1199.9px) and (min-width: 991.9px) {
  .lg-0 {
    display: none;
  }

  .lg-1 {
    grid-column: span 1;
  }

  .lg-2 {
    grid-column: span 2;
  }

  .lg-3 {
    grid-column: span 3;
  }

  .lg-4 {
    grid-column: span 4;
  }

  .lg-5 {
    grid-column: span 5;
  }

  .lg-6 {
    grid-column: span 6;
  }

  .lg-7 {
    grid-column: span 7;
  }

  .lg-8 {
    grid-column: span 8;
  }

  .lg-9 {
    grid-column: span 9;
  }

  .lg-10 {
    grid-column: span 10;
  }

  .lg-12 {
    grid-column: span 12;
  }
}

@media only screen and (max-width: 991.9px) and (min-width: 767.9px) {
  .md-0 {
    display: none;
  }

  .md-1 {
    grid-column: span 1;
  }

  .md-2 {
    grid-column: span 2;
  }

  .md-3 {
    grid-column: span 3;
  }

  .md-4 {
    grid-column: span 4;
  }

  .md-5 {
    grid-column: span 5;
  }

  .md-6 {
    grid-column: span 6;
  }

  .md-7 {
    grid-column: span 7;
  }

  .md-8 {
    grid-column: span 8;
  }

  .md-9 {
    grid-column: span 9;
  }

  .md-10 {
    grid-column: span 10;
  }

  .md-12 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 767.9px) {
  .pagination_desk_none {
    padding-bottom: 2.4rem;

    .swiper-pagination {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 767.9px) {
  .sm-0 {
    display: none;
  }

  .sm-6 {
    grid-column: span 6;
  }

  .sm-12 {
    grid-column: span 12;
  }

  html {
    font-size: 10px;
  }

  .container,
  .container-second {
    max-width: 100%;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .breadCrumb {
    display: none !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #121212;
}

input,
textarea,
select {
  color: #444;
  background-color: transparent;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  outline: 0;
  cursor: pointer;
}
.collectionHeader iframe {
  margin-top: 4.8rem;
  width: 100%;
  height: 58rem;
}
.collectionHeaderCreate p {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 2rem !important;
  line-height: 2.3rem !important;
  text-align: center;

  color: #121212 !important;
}
button,
input,
textarea,
select {
  outline: 0;
}

ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
// .title,
h1,
h5.title,
.newsDetailHeader h5,
.selectedForYouHeader .title,
.collections .title h4,
.newsSection .title,
.aboutSectionChild .title,
.collectionHeader h4,
.collectionBottom div h6,
.giftbodyTextchild .title h5,
.stores h2.title,
.support .supportMain h4
// h4
// .orderHistoryDetail h6,
// .account-header h5,
// // .supportSidebar a,
// .support .supportBody .supportContentText h3,
// .support .supportBody.delivery .delivery-content p strong,
// .support .supportBody .supportContent.payment h3,
// .aboutPage .aboutPageContent .subtitle,
// .about-card h6,
// .about-card-black h5,
// .giftCard .giftBodyText .giftbodyTextchild .title h5
{
  font-family: "Playfair Display", serif !important;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.headerBottom .brandListMenu ul a {
  font-family: "Montserrat", sans-serif !important;
  text-transform: capitalize;
}

.more-category {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  a,
  button {
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 2rem;

    color: #121212;
    border-bottom: 0.08rem solid #121212;
    position: relative;
    transition: 0.3s;
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;

    // &::after {
    //   position: absolute;
    //   content: "";
    //   transition: 0.3s;
    //   right: 0;
    // }

    &:hover {
      color: #ff0852;
      border-bottom: 0.08rem solid #ff0852;

      // &::after {
      //   bottom: -0.1rem;
      //   height: 0.1rem;
      //   right: 2.4rem;
      //   width: 0.9rem;
      //   background-color: #fff;
      // }
    }

    &:disabled {
      opacity: 0.35;
    }
  }
}

.breadCrumb {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.3rem;

    color: #000000;
    border-bottom: 0.1rem solid #000000;
    padding-bottom: 0.4rem;
    z-index: 3;
    transition: 0.3s;
    position: relative;

    // &::after {
    //   position: absolute;
    //   content: "";
    //   transition: 0.3s;
    //   right: 0;
    // }

    &:hover {
      color: #ff0852;
      border-bottom: 0.1rem solid #ff0852;

      // &::after {
      //   bottom: -0.1rem;
      //   height: 0.1rem;
      //   right: 0.4rem;
      //   width: 0.4rem;
      //   background-color: #fff;
      // }
    }
  }

  svg {
    transform: rotate(-90deg);
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 0.8rem;

    path {
      stroke: #22282f;
    }
  }

  div {
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.3rem;

    color: #000000;
    opacity: 0.4;
    border: none;
    pointer-events: none;
    position: static;
    text-transform: inherit;
    transition: 0.3s;
    border-bottom: 0.1rem solid transparent;
    padding-bottom: 0.4rem;
  }

  .secondLinkParent {
    display: flex;
    align-items: center;

    .secondLinkChild {
      display: none;
    }
  }

  &.active {
    display: flex;

    .secondLinkParent {
      .secondlink {
        opacity: 1;
        border-bottom: 0.1rem solid #000000 !important;
        pointer-events: auto;
        position: relative;

        &::after {
          position: absolute;
          content: "";
          right: 0;
          transition: 0.3s;
        }

        &:hover {
          color: #ff0852;
          border-bottom: 0.1rem solid #ff0852 !important;

          &::after {
            bottom: -0.1rem;
            right: 1rem;
            height: 0.1rem;
            width: 0.9rem;
            background-color: #fff;
          }
        }
      }

      .secondLinkChild {
        display: flex;
        align-items: center;

        .secondlink {
          opacity: 0.4;
          border: none !important;
          pointer-events: none;
        }
      }
    }
  }
}

.input-group {
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;

  input,
  textarea {
    background: #ededed;
    border-radius: 1.5rem;
    height: 5.4rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.1rem;

    color: #121212;
    width: -webkit-fill-available;
    border: none;
    padding: 0 3.6rem;

    &::placeholder {
      color: rgba(18, 18, 18, 0.5);
    }
  }

  .input-child {
    width: 48%;

    &:last-child {
      margin-left: 1.6rem;
    }
  }
  textarea {
    height: 10rem;
    padding: 2.4rem 3.6rem;
    resize: none;
  }
}

.blackButton {
  background: #121212;
  border-radius: 3rem;
  height: 5.8rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.1rem;
  text-align: center;

  color: #fdfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  transition: 0.3s;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.4rem;

    path {
      stroke: #fdfcfc;
    }
  }

  &:hover {
    background: #700022;
  }

  &:focus {
    background: #310713;
  }

  &:disabled {
    background: #12121235;
  }
}

.greenButton {
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.1rem;
  text-align: center;

  background: #2fb12f;
  border-radius: 3rem;
  color: #fdfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  border: none;
  padding: 0 4rem;
  transition: 0.3s;

  &:hover {
    background-color: #248924;
  }

  &:focus {
    background-color: #1a611a;
  }

  &:disabled {
    background-color: rgba(47, 177, 47, 0.35);
  }
}

.filterCheckbox {
  position: relative;
  padding-left: 3.2rem;
  margin-bottom: 1.6rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;

  span {
    margin: 0;
    margin-top: 0.4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;

    color: #000000;
    transition: 0.3s;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 2rem;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.9rem;
    width: 1.9rem;
    background-color: transparent;
    border: 1px solid #12121220;
    box-sizing: border-box;
    border-radius: 0.4rem;
    transition: 0.3s;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: #ff0852;
    border-radius: 0.2rem;
    width: 1.1rem;
    height: 1.1rem;
  }

  &:hover {
    span {
      color: #ff0852;
    }

    .checkmark {
      border: 0.1rem solid #ff085220;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
.breadCrumbsContainer {
  padding-bottom: 1.2rem;
}
.createP {
  margin: 4.4rem 0;
  p,
  ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;

    color: #12121296;
  }
  li {
    margin-bottom: 1.6rem;
  }
  p {
    width: 100% !important;
    text-align: left !important;
    margin-bottom: 2.4rem;
  }
}
.filterRadio {
  position: relative;
  padding-left: 3.2rem;
  margin-bottom: 1.6rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;

  svg {
    width: 1.3rem;
    height: 1.1rem;
    margin-left: 0.2rem;

    path {
      transition: 0.3s;
    }
  }

  span {
    margin: 0;
    margin-top: 0.4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;

    color: #000000;
    transition: 0.3s;
    text-transform: lowercase;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    height: 2rem;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.9rem;
    width: 1.9rem;
    border: 0.1rem solid #12121220;
    box-sizing: border-box;
    border-radius: 50%;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    background: #ff0852;
  }

  &:hover {
    span {
      color: #ff0852;
    }

    .checkmark {
      border: 0.1rem solid #ff085220;
    }

    svg {
      path {
        fill: #ff0852;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.errorPage {
  padding: 1.2rem 0 7.5rem;

  .error-page-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 7.4rem;

    a {
      margin-bottom: 2.8rem;
      display: block;

      svg {
        width: 22.4rem;
        height: 15.4rem;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 2rem;
      line-height: 2.4rem;

      color: #12121296;
      margin-bottom: 3.2rem;
    }
  }

  .errorContentBottom {
    background: #f6f5f5;
    border-radius: 1.5rem;
    padding: 6.4rem 0 4.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 2rem;
      line-height: 2.3rem;

      color: #121212;
      opacity: 0.56;
      margin-bottom: 2.4rem;
    }

    .errorContentBottomLink {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 3.6rem;

      a {
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.1rem;

        color: #000000;
        border-bottom: 0.08rem solid #121212;
        margin-right: 1.8rem;
        padding-bottom: 0.3rem;
      }
    }
  }

  .errorPageButton {
    width: fit-content;
    padding: 0 4.4rem;
  }

  @media only screen and (max-width: 767.9px) {
    .error-page-content {
      padding-top: 4.8rem;

      a {
        margin-bottom: 0;

        svg {
          width: 13rem;
          height: 14rem;
        }
      }

      p {
        font-size: 1.7rem;
        line-height: 2rem;
        margin-bottom: 2.8rem;
      }
    }

    .errorContentBottom {
      padding: 3.6rem 1.2rem 4.4rem;

      p {
        font-size: 1.5rem;
        line-height: 155%;
        text-align: center;
        margin-bottom: 3.2rem;
      }

      .errorContentBottomLink {
        justify-content: start;
        width: 90%;

        a {
          font-size: 1.8rem;
          line-height: 2.1rem;
          margin-bottom: 2.2rem;
        }
      }
    }
  }
}
.desktopWpIcon {
  border-radius: 50%;
  width: 6.4rem;
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  background-color: #15971a;
  z-index: 10;
  transition: 0.4s;
  svg {
    width: 3.2rem;
    height: 3.2rem;
    path {
      fill: #fff;
    }
  }
  &:hover {
    background-color: #000000;
  }
}
.makeStyles-copyContainer-5 {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.makeStyles-container-1 {
  padding: 0 !important;
  background-color: transparent;
  max-width: fit-content !important;
  min-width: fit-content !important;
  display: flex;
  align-items: center;
  background-color: transparent !important;
  .makeStyles-iconContainer-3 {
    display: flex;
    align-items: center;
    padding: 0 !important;
    button {
      background: #f3f3f3;
      width: 4rem !important;
      height: 4rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.8rem;
      border-radius: 50%;
      padding: 0 !important;
      svg {
        width: 4rem !important;
        height: 4rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        path {
          fill: black;
          width: 1.9rem !important;
          height: 1.9rem !important;
          transform: translate(19%, 19%) scale(0.65);
        }
        circle {
          fill: #f3f3f3;
        }
      }
    }
  }
  .makeStyles-copyUrl-6 {
    width: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0 !important;
  }
  .makeStyles-copyIcon-7 {
    width: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0 !important;
  }
}
.errorPageButton {
  width: 100%;
  padding: 0;
  font-size: 1.6rem;
  line-height: 1.9rem;
}
.likeTrash {
  position: absolute;
  right: 5rem;
  top: 2rem;
  cursor: pointer;
  display: none;
  input {
    opacity: 0;
    position: absolute;
  }
}
.favoritesPage {
  .likeTrash {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: #000000;
  }
}
.heart {
  position: absolute;
  right: 2.1rem;
  top: 2rem;
  cursor: pointer;
  display: block;

  &:hover {
    svg {
      stroke: #fff;

      path {
        &:first-child {
          stroke: #ff0852;
        }

        &:last-child {
          stroke: #ff0852;
        }
      }
    }
  }

  .like-button {
    opacity: 0;

    &:checked + svg {
      path {
        &:first-child {
          fill: #ff0852;
          stroke: #ff0852;
        }

        &:last-child {
          stroke: #fff;
        }
      }
    }
  }

  svg {
    width: 2.4rem;
    height: 2.2rem;

    path {
      transition: 0.3s;
    }
  }
}

.account-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3.4rem 0;
  position: relative;

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 5.6rem;
    text-align: center;

    color: #121212;
    margin-bottom: 0.8rem;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.3rem;
    text-align: center;

    color: #121212;
    opacity: 0.36;
  }

  .logOutButton {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.6rem;
    cursor: pointer;
    color: #121212;
    opacity: 0.76;
    border: none;
    border-bottom: 0.1rem solid #000000;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    background-color: transparent;
    padding: 0;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 767.9px) {
    .title {
      h5 {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }

      p {
        font-size: 1.4rem;
        line-height: 155%;
      }
    }
  }
}

.accountForm {
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;

    .input-child {
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;

        color: #121212;
        opacity: 0.7;
        // margin-bottom: .8rem;
        display: block;
        padding: 0 1rem;
      }

      input {
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.1rem;

        color: #121212;
        border-bottom: 0.2rem solid #dedede;
        background-color: transparent;
        border-radius: 0;
        height: 4rem;
        padding: 0 1rem;
      }

      &:last-child {
        margin-left: 1.6rem;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .map {
    padding: 2.4rem 0;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 1.5rem;

      color: #121212;
      margin-bottom: 2rem;
    }

    button {
      margin-top: 3.2rem;
      width: 37rem;
    }
  }

  @media only screen and (max-width: 767.9px) {
    .input-group {
      display: flex;
      flex-direction: column;
      margin: 0;

      .input-child {
        margin-left: 0 !important;
        margin-bottom: 2.4rem;

        input {
          background-color: transparent;
        }
      }
    }

    button {
      width: 100% !important;
      font-size: 1.7rem;
      line-height: 2rem;
      height: 5.1rem !important;
      margin: 0;
    }
  }
}

.account-title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.1rem;

  color: #121212;
  margin-bottom: 4.2rem;
}

.positionRelative {
  position: relative;
}
.infinite-scroll-component {
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.1rem;

  color: #121212;
  white-space: nowrap;
}
.phone-child {
  opacity: 0.5;
  pointer-events: none;
}
.support,
.profile {
  .positionRelative {
    position: relative;
    border-right: 0.1rem solid #d6d6d6;
  }

  .supportSidebar,
  .accountSidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 6.4rem;
  }
}
.addressCard {
  background: #fff;
  border: 0.1rem solid rgba(16, 7, 24, 0.07);
  box-sizing: border-box;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.3s;
  height: 27rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;

  .addressCardInnerBottom {
    position: relative;
    height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3.6rem;
    border-top: 0.1rem solid rgba(16, 7, 24, 0.07);

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 170%;
      color: #1e0f34;
    }

    .buttons {
      display: flex;
      align-items: center;

      button {
        background-color: transparent;
        border: none;
        // background-color: red;
        padding: 0;
        width: 2.4rem;
        height: 2.4rem;
        margin-left: 1.6rem;
      }
    }
  }

  .addressCardInner {
    height: 100%;
    padding: 1.6rem;

    h2.title {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 1.2rem !important;
      line-height: 170% !important;
      color: #12121260 !important;
      margin-bottom: 1rem !important;

      p {
        font-weight: 600 !important;
        font-size: 1.4rem !important;
        line-height: 1.6rem !important;

        color: #121212 !important;
        margin-top: 0.3rem !important;
      }
    }
  }
  .addressCardİnnerDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3.2rem;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 5rem;
    width: 2rem;
    z-index: 2;
    left: 0;
    width: 50%;
    top: 0;
  }

  input:checked + .checkmark {
    background-color: #ff0852;

    &::after {
      display: block;
    }
  }

  input:checked + .addressCard {
    background-color: #ff0852;

    &::after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 1rem;
    height: 1.6rem;
    width: 1.6rem;
    background-color: transparent;
    border-radius: 0.5rem;
    border: 0.1rem solid #ff0852;

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 0;
      top: 0;
      bottom: 0.2rem;
      right: 0;
      margin: auto;
      width: 0.23em;
      height: 0.6rem;
      border: solid white;
      border-width: 0 0.2rem 0.2rem 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.addressAddCard {
  border: 0.1rem solid rgba(16, 7, 24, 0.07);
  height: 27rem;
  box-sizing: border-box;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  position: relative;

  h4 {
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    margin: auto;
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.6rem;

    color: #121212;
    opacity: 0;
    transition: 0.3s;
  }

  svg {
    width: 8rem;
    height: 8rem;
  }

  &:hover {
    h4 {
      opacity: 1;
    }
  }
}

.selectBox {
  width: 100%;
  position: relative;

  &::after {
    position: absolute;
    right: 1.6rem;
    content: "";
    background-image: url("./assets/arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 2.4rem;
    width: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  select {
    background: #f3f3f3;
    border-radius: 1.5rem;
    height: 5.4rem;
    width: 100%;
    appearance: none;
    border: none;
    padding: 0 3.4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.1rem;

    color: rgba(18, 18, 18, 0.7);
  }
}

.authContentFooterChild-second {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.6rem;

  a,
  button {
    background: #f1efef !important;
    border-radius: 3rem !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 1.2rem !important;
    border: none !important;
    box-shadow: none !important;
    line-height: 1.4rem !important;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.2rem;
    width: 50%;
    transition: 0.3s;
    border: none;
    svg {
      margin-right: 1rem !important;
      width: 2.1rem !important;
      height: 2.1rem !important;
    }
    span {
      font-weight: 600 !important;
      font-size: 1.2rem !important;
      line-height: 1.4rem !important;
    }

    &.FaceBook {
      color: rgba(4, 52, 242, 0.75);
      margin-right: 1rem;
    }

    &.Google {
      color: #4063f1 !important;
    }
    div {
      margin: 0 !important;
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f1efef !important;
    }
    &:hover {
      background: #fefefe;
      box-shadow: 0 0.8rem 2rem rgba(196, 196, 196, 0.2);
    }

    &:focus {
      background: #f3f0f0;
      box-shadow: 0 0.8rem 2rem rgba(196, 196, 196, 0.2);
    }

    &:disabled {
      background: #f1efef;
      opacity: 0.75;
      box-shadow: none;
    }
  }
}

.authContentFooterChild {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  margin-top: 2.8rem;

  a,
  button {
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.4rem;

    color: #121212;
    opacity: 0.65;
    border: none;
    border-bottom: 0.07rem solid #121212;
    padding: 0 0 0.4rem 0;
    transition: 0.3s;
    background-color: transparent;

    &:hover {
      opacity: 1;
    }
  }
}

.collectionBottom,
.collectionBottom div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12.6rem 0 5.4rem;

  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 6.4rem;
    line-height: 7.5rem;
    text-align: center;

    color: #121212;
    margin-bottom: 1.8rem;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 155%;
    text-align: center;

    color: #121212;
    opacity: 0.86;
    // margin-bottom: 3.6rem;
    margin-bottom: 3.2rem;
    width: 65%;
  }

  .blackButton {
    width: fit-content;
    padding: 0 4rem;
  }

  @media only screen and (max-width: 767.9px) {
    padding: 3.6rem 0 1.8rem;

    h6 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    p {
      font-size: 1.4rem;
      line-height: 155%;
      width: 100%;
    }

    .blackButton {
      height: 5.2rem;
      font-size: 1.4rem;
      padding: 0 1rem;
      line-height: 1.9rem;
    }
  }
}
.collectionBottom div {
  padding: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.addressCardInnerBottom {
  .buttons {
    button {
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
}
.popup {
  background: rgba(239, 239, 239, 0.374);
  box-shadow: inset 77.9px -77.9px 77.9px rgba(186, 186, 186, 0.174),
    inset -77.9px 77.9px 77.9px rgba(255, 255, 255, 0.174);
  position: fixed;
  top: 0;
  left: 0;
  // background-color: red;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  backdrop-filter: blur(2.8rem);
}

.popupContainer {
  background: #ffffff;
  box-shadow: 0 4.4rem 4.4rem rgba(113, 102, 102, 0.15);
  border-radius: 0.4rem;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.registerPopup .popupContainer {
  width: 70vw;
  max-height: 50rem;

  .popupInner {
    position: relative;
    padding: 2.6rem 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: -webkit-fill-available;

    .popupClose {
      position: absolute;
      right: 3.8rem;
      top: 3.5rem;
      padding: 0;
      background-color: transparent;
      border: none;

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .popupInnerBig {
      width: 55%;
      height: 100%;
      object-fit: cover;
    }

    .popupInnerContent {
      width: 45%;

      .popupInnerContentChild {
        padding-left: 4.8rem;
        padding-right: 2.4rem;

        h4 {
          font-style: normal;
          font-weight: 600;
          font-size: 3.2rem;
          line-height: 144.4%;

          color: #121212;
          margin-bottom: 1.2rem;
        }

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 155.4%;

          color: #121212;
          opacity: 0.76;
          margin-bottom: 2.9rem;
        }

        .blackButton {
          height: 5.8rem;
          font-size: 2rem;
          width: 100%;
        }
      }
    }
  }
}
.brandsPage {
  padding: 7.2rem 0;
  .brandsDetail {
    margin-top: -20rem;
  }
  .titleAlp {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25rem !important;
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 3rem;
      line-height: 155%;
      color: #121212;
      margin-bottom: 3.4rem;

      position: relative;
      width: fit-content;
      text-align: center;

      &::after {
        content: "";
        position: absolute;
        width: 3.2rem;
        height: 0.1rem;
        background-color: rgba(18, 18, 18, 0.773);
        top: 0;
        bottom: 0;
        margin: auto;
        right: 4.4rem;
      }
      &::before {
        content: "";
        position: absolute;
        width: 3.2rem;
        height: 0.1rem;
        background-color: rgba(18, 18, 18, 0.773);
        top: 0;
        bottom: 0;
        margin: auto;
        left: 4.4rem;
      }
    }
  }
  // .row {
  //   padding-bottom: 4.8rem;
  // }
  .brandCard {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
    .imgCard {
      border: 0.08rem solid rgba(18, 18, 18, 0.15);
      height: 18rem;
      width: 18rem;
      background-color: #fff;
      max-height: 18rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
    }
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 155%;
      color: #121212;
      margin-top: 2.4rem;
      text-align: center;
    }
  }
}
.productSlides {
  position: relative;
  overflow: hidden;

  .swiper {
    position: static;

    .productCardAddToCart,
    .mobileAddToCart {
      display: none;
    }

    .swiper-wrapper {
      padding-bottom: 2rem;
    }

    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .swiper-button-prev {
    left: -4rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;

    &::after {
      transform: rotate(-180deg);
    }
  }

  .swiper-button-next {
    right: -4rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    z-index: 3;
    width: 5.5rem;
    height: 9rem;
    background: #f3f3f3;
    top: -6rem;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;

    &::after {
      content: url("./assets/ArrowS.svg");
    }
  }

  @media only screen and (max-width: 1199px) {
    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev {
      left: 0;
    }
  }
}

@media only screen and (max-width: 767.9px) {
  .popup {
    &.cartPopup,
    &.registerPopup {
      .popupContainer {
        width: 90vw;
        max-height: 45rem !important;

        .popupInner {
          display: flex;
          flex-direction: column;
          padding: 1.1rem;
          justify-content: flex-start;

          .popupInnerBig {
            width: 100%;
            height: 20.4rem;
            margin-bottom: 3.4rem;
          }

          .popupInnerContent {
            width: -webkit-fill-available;
            padding: 0 0.8rem;

            .popupInnerContentChild {
              padding: 0;

              h4 {
                font-size: 2rem;
                line-height: 144.4%;
                margin-bottom: 0.8rem;
              }

              p {
                font-size: 1.3rem;
                line-height: 155.4%;
                margin-bottom: 1.2rem;
              }

              .blackButton {
                height: 4.8rem;
                font-size: 1.7rem;
                line-height: 2rem;
              }
            }
          }

          .popupClose {
            background: #ffffff;
            box-shadow: 0 0.8rem 2rem rgba(196, 196, 196, 0.2);
            height: 4.5rem;
            width: 4.5rem;
            border-radius: 50%;
            right: -1.6rem;
            top: -1.6rem;

            svg {
              width: 1.4rem;
              height: 1.4rem;
            }
          }
        }
      }
    }

    &.cartPopup {
      .popupContainer {
        .popupInner {
          .img {
            width: 21rem;
            height: 15.7rem;
            margin-bottom: 2.7rem;
            border-bottom: 0.1rem solid rgba(18, 18, 18, 0.05);
            padding-bottom: 2.7rem;
          }

          .popupInnerRight {
            padding-left: 0;
            margin-left: 0;
            height: 100%;
            width: 100%;
            border-left: none;
            justify-content: flex-start;

            h5 {
              font-size: 2.4rem;
              line-height: 144.4%;
              margin-bottom: 1.5rem;
              text-align: center;
            }

            p {
              font-size: 1.2rem;
              line-height: 155.4%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.lazy-load-image-background {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.sizeCard {
  .lazy-load-image-background {
    height: fit-content;
  }
}
.cartPopup .popupContainer {
  width: 70vw;
  max-height: 50rem;

  .popupInner {
    position: relative;
    padding: 2.6rem 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: -webkit-fill-available;

    .popupClose {
      position: absolute;
      right: 3.8rem;
      top: 3.5rem;
      padding: 0;
      background-color: transparent;
      border: none;

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .img {
      width: 24rem;
      height: 25.6rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .popupInnerRight {
      padding-left: 7.2rem;
      margin-left: 7.2rem;
      border-left: 0.1rem solid rgba(18, 18, 18, 0.1);
      // height: 100%;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 144.4%;

        color: #2fb12f;
        margin-bottom: 0.8rem;
      }

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 155.4%;

        color: #121212;
        opacity: 0.86;
      }
    }
  }
}

.more-search {
  button,
  a {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;

    color: #121212;
    background-color: transparent;
    border: none;
    border-bottom: 0.1rem solid #121212;
    margin-left: 9.6rem;
    width: fit-content;
    padding: 0;
    margin-top: 1.6rem;
    position: relative;
    transition: 0.3s;

    &::after {
      position: absolute;
      content: "";
      transition: 0.3s;
      right: 0;
    }

    &:hover {
      border-bottom: 0.08rem solid #121212;

      &::after {
        bottom: -0.1rem;
        height: 0.1rem;
        right: 2.4rem;
        width: 0.9rem;
        background-color: #fff;
      }
    }
  }
}

.desk-none {
  display: none !important;
}

.resp-none {
  display: flex;
}

.row.resp-none {
  display: grid;
}
.swiper-pagination {
  z-index: 2 !important;
}
.paginationSwiper {
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0 !important;
  }

  .swiper-pagination-bullet {
    border: 0.07rem solid #868686;
    box-sizing: border-box;
    border-radius: 3rem;
    width: 1.8rem;
    height: 0.4rem;
    background: transparent;
    transition: 0.3s;

    &:hover {
      background: #ff0852;
      border: 0.07rem solid #ff0852;
      opacity: 1;
    }

    &:focus {
      background-color: #700022;
      border: 0.07rem solid #700022;
    }
  }

  .swiper-pagination-bullet-active {
    background: #121212;
    width: 5.4rem;
    border: 0.07rem solid #121212;
    transition: 0.3s;

    &:hover {
      background: #121212;
      border: 0.07rem solid #121212;
    }
  }
}
.bonusCard {
  border-radius: 1rem;
  border: 0.1rem solid #e8e8e8;
  background: #fff;
  box-shadow: 8px 8px 24px 0px rgba(108, 107, 123, 0.25);
  padding: 3rem 2.8rem 0;
  height: 15rem;
  img {
    width: 100%;
    height: 5.9rem;
    object-fit: contain;
  }
  .bonusBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.8rem;
    div {
      display: flex;
      align-items: center;
      img {
        width: 4.6rem;
        height: 4.6rem;
        object-fit: contain;
        margin-right: 1.4rem;
      }
      p {
        color: #000;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        span {
          display: block;
          margin-left: 0.5rem;
        }
      }
    }
    strong {
      color: #000;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      opacity: 0.5;
    }
  }
}

@media only screen and (max-width: 767.9px) {
  .resp-none {
    display: none !important;
  }

  .desk-none {
    display: flex !important;
  }

  .breadCrumb {
    display: none;
  }

  .positionRelative {
    border-right: none !important;
  }

  .account-title {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 2.4rem;
  }

  .profile {
    background-color: #fdfcfc;
  }
}

.headerTab {
  display: none;
}
.brandsPageTop {
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a {
    color: #121212;
    display: -webkit-box;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;

    line-height: 155%;
    margin-right: 1rem;

    transition: 0.3s;
    &:hover {
      color: #ff0852;
    }
  }
  span {
    color: #121212;
    display: -webkit-box;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;

    line-height: 155%;
    text-transform: capitalize;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 3.2rem;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 0.1rem;
      width: 35%;
      background-color: #121212;
    }
    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 0.1rem;
      width: 35%;
      background-color: #121212;
    }
  }
}
@media only screen and (max-width: 767.9px) {
  .desktopWpIcon {
    display: none;
  }
  .addressAddCard,
  .addressCard {
    margin-bottom: 1.6rem;
  }
  .headerTab {
    position: fixed;
    bottom: 0.6rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 98%;
    height: 6.7rem;
    z-index: 4;
    background: #f8f8f8;
    border: 0.1rem solid #f8f8f8;
    box-sizing: border-box;
    box-shadow: 0 0.8rem 2rem rgba(196, 196, 196, 0.2);
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .headerTabInner {
      padding: 0 4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;

      .headerTabIcon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
          width: 3rem;
          height: 3rem;
        }

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 1.1rem;
          line-height: 1.3rem;

          color: #121212 !important;
          display: block;
          margin-top: 0.4rem;
        }
      }

      .cartCurrent {
        position: relative;

        .currentSpan {
          position: absolute;
          background: #15971a;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          font-size: 1.3rem;
          line-height: 1.5rem;
          text-align: center;
          color: #fdfcfc !important;
          display: flex;
          align-items: center;
          justify-content: center;
          top: -1rem;
          right: -1.6rem;
        }
      }
    }
  }
}

.input-group-validate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  // margin-bottom: 3.2rem;

  .srv-validation-message {
    color: #ff0852;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2rem;
    opacity: 0.65;

    position: absolute;
    left: 1.2rem;
    top: 6rem;
    white-space: nowrap;
  }
}

.loading-indicator {
  position: relative;

  &::before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1rem);
    z-index: 10;
    background: rgba(239, 239, 239, 0.374);
    box-shadow: inset 7.7rem -7.7rem 7.7rem rgba(186, 186, 186, 0.174),
      inset -7.7rem 7.7rem 7.7rem rgba(255, 255, 255, 0.174);
  }

  &::after {
    content: "";
    background: transparent;
    border: 1rem solid #000000;
    border-radius: 50%;
    border-top: 1rem solid #ff0852;
    width: 6.4rem;
    height: 6.4rem;
    top: 0;
    left: 0;
    position: fixed;
    animation: spin 2s linear infinite;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 11;

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.productDetailSize {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .swiper {
    margin-bottom: 1.2rem;
    // height: 36rem;
    .swiper-slide {
      height: 17.2rem;
      margin-bottom: 1.2rem;
    }
    .swiper-wrapper {
      display: flex;
      flex-wrap: wrap;
      height: auto;
    }
  }
  label {
    width: 50%;
    width: 19.7rem;
    height: 17.2rem;
    // position: relative;

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      cursor: pointer;
      opacity: 0;

      &:checked + .sizeCard {
        border: 0.2rem solid #001301;
        box-sizing: border-box;
      }
    }

    .sizeCard {
      background: #ffffff;
      border: 0.1rem solid #e9e9e9;
      box-sizing: border-box;
      border-radius: 1.5rem;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 17.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        max-width: 5.2rem;
        max-height: 5.2rem;
        object-fit: contain;
        margin-bottom: 1.8rem;
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.6rem;
        text-align: center;

        color: #121212;
        margin-bottom: 0.8rem;
      }

      .price {
        font-style: normal;
        font-weight: 600;
        font-size: 2.8rem;
        line-height: 3.3rem;

        color: #121212;
        display: flex;
        align-items: flex-end;

        svg {
          width: 1.7rem;
          height: 1.4rem;
        }

        span {
          font-size: 1.6rem;
          display: flex;
          align-items: center;
          margin-left: 1.6rem;
          margin-bottom: 0;
          position: relative;

          &::after {
            width: 100%;
            height: 0.1rem;
            background-color: #ff0852;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: (rotate(8deg));
          }

          svg {
            width: 1rem;
            height: 0.8rem;
          }
        }
      }
    }

    &:nth-child(1),
    &:nth-child(3) {
      margin-right: 1.6rem;
    }
  }
}

.productDetailQuantity {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid #121212;
  box-sizing: border-box;
  border-radius: 3rem;
  width: 11.6rem;
  height: 5.6rem;
  // margin-right: 1rem;
  .quantity-picker {
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .quantity-modifier {
      padding: 0;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;

      &:hover {
        background-color: transparent;
      }
    }
  }

  button {
    font-style: normal;
    font-weight: normal;
    font-size: 2.4rem;
    line-height: 2.3rem;

    color: #121212;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  input {
    width: 6.4rem;
    text-align: center;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 2.6rem;

    color: #121212;
    padding: 0;
  }
}

.backToShop {
  height: 4rem;
  font-size: 1.4rem;
  width: fit-content;
  padding: 0 1.4rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}
.__jivoMobileButton {
  position: fixed !important;
  left: -1rem !important;
  bottom: 12rem !important;
}
.addToCard {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff0852;
  border-radius: 3rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.9rem;

  color: #fdfcfc;
  height: 5.6rem;
  padding: 0;
  border: none;
  padding: 0 2.4rem;
  transition: 0.3s;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;

    path {
      stroke: #fff;
    }
  }

  &:hover {
    background: #700022;
  }

  &:focus {
    background: #310713;
  }

  &:disabled {
    background: rgba(255, 8, 82, 0.35);
  }
}
.topToButton {
  position: fixed;
  bottom: 3.2rem;
  right: 3.2rem;
  background-color: #121212;
  box-shadow: 0 0.1rem 3rem rgba(108, 108, 108, 0.5);
  width: 6.4rem;
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 50%;
  cursor: pointer;

  svg {
    transform: rotate(180deg);
    path {
      fill: #fff;
    }
  }
  @media (max-width: 767.9px) {
    bottom: 9.2rem;
    right: 2.4rem;
  }
}

.changePasswordPage {
    padding: 1.2rem 0 8.8rem;

    .account-changePassword {
        padding: 3.2rem 14.8rem;
        width: 50%;

        .blackButton {
            width: 90%;
            height: 5.2rem;
        }
    }

    @media only screen and (max-width: 767.9px) {
        .account-changePassword {
            width: 100%;
            padding: 2.3rem 0;
        }
    }
}
.forgetPassword {
  padding: 1.2rem 0 8rem;
  background: #FDFCFC;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    .forgetPasswordContent {
      margin-top: 5rem;
      background: #fdfcfc;
      border: 0.1rem solid #ebebeb;
      box-sizing: border-box;
      border-radius: 3rem;
      padding: 4rem 8.4rem;

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 4.4rem;
        color: #121212;
        margin-bottom: 2.2rem;
        text-align: center;
      }
      .input-group {
        width: 44.1rem;
      }
    }
    .forgetInfo {
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: center;
      
      color: #2d2c2d;
      display: block;
      margin-top: 1.2rem;
    }
  }
  @media(max-width: 767.9px){
    .container{
        .forgetPasswordContent{
            margin-top: 2.4rem;
            padding: 2.4rem 1.6rem;
            width: -webkit-fill-available;
            .title{
                font-size: 2rem;
                margin-bottom: 1.2rem;
            }
            .input-group {
                width: 100%;
            }
        }
    }
  }
}

.favoritesPage {
  padding: 1.2rem 0 8.8rem;
  background-color: #fdfcfc;

  .account-fav {
    padding: 2.4rem 1.6rem;
  }

  .cartEmpty {
    background-color: #fdfcfc;
    width: 100%;
    border-radius: 1.5rem;

    .cartEptyContent {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding-top: 7.4rem;
      padding-bottom: 6.4rem;

      a {
        margin-bottom: 2.8rem;
        display: block;

        svg {
          width: 22.4rem;
          height: 15.4rem;
        }
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 2rem;
        line-height: 2.4rem;
       
        color: #12121296;
        margin-bottom: 3.2rem;
      }
    }

    .cartEmptyButton {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: fit-content;
      padding: 0 4.2rem;
    }
  }
  @media (max-width: 767.9px) {
    .cartEptyContent {
      a {
        margin-bottom: 1rem;
        svg {
          width: 16rem !important;
          height: 10rem !important;
        }
      }
      p {
        font-size: 1.6rem !important;
      }
    }
  }
}

.contentCategory {
  background: #f8f0f0;

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 56rem;

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.6rem;
      
      
      color: #121212;
      margin-bottom: 1.6rem;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 155.5%;
      
      color: #121212;
      margin-bottom: 2.4rem;
    }

    // a {
    //     font-style: normal;
    //     font-weight: 600;
    //     font-size: 1.8rem;
    //     line-height: 2.1rem;
    //     
    //     
    //     color: #121212;
    //     border-bottom: 0.08rem solid #121212;
    //     width: fit-content;
    // }
    .more-category {
      display: flex;
      justify-content: flex-start;
    }
  }

  .ContentCategoryImg {
    img {
      width: 100%;
      height: 48.9rem;
      object-fit: cover;
    }
  }

  @media only screen and (max-width: 767.9px) {
    .ContentCategoryImg {
      order: -1;

      img {
        width: 100%;
        height: 18rem;
        object-fit: contain;
      }
    }

    .left {
      height: fit-content;
      padding-bottom: 3.8rem;

      h1 {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }

      p {
        font-size: 1.3rem;
        line-height: 155.5%;
      }

      a {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
  }
}

.newsDetail {
  padding: 1.2rem 0 9.8rem;

  .newsDetailHeader {
    padding: 3.6rem 0 5.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 3.6rem;
      line-height: 144%;
      text-align: center;

      color: #121212;
      margin-bottom: 1.6rem;
      width: 50%;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 100%;

      color: #121212;
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        padding-left: 1.6rem;
        position: relative;
        border-left: 0.1rem solid #cbcbcb;
        margin-left: 1.6rem;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.6rem;
        }
      }
    }

    img {
      max-width: 70%;
      width: fit-content;
      height: auto;
      object-fit: contain;
      margin-top: 3.2rem;
      border-radius: 3rem;
    }
  }

  .newsdetailContent {
    width: 90%;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 3.2rem;
      line-height: 144%;

      color: #121212;
      margin-bottom: 1.8rem;
    }

    img {
      width: 100% !important;
      min-height: 30rem;
      height: 50rem !important;
      object-fit: cover;
      border-radius: 1.5rem !important;
      margin-bottom: 2.4rem;
      overflow: hidden;
    }
    li {
      // display: flex;
      // align-items: center;
      padding-left: 3rem;
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 155%;

      font-feature-settings: "pnum" on, "lnum" on, "liga" off, "kern" off;
      color: #121212;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 1rem;
        background: #121212;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
      }
      a {
        display: inline-block;
        color: #ff0852;
        transition: 0.4s;
        &:hover {
          opacity: 0.6;
        }
      }
    }
    a {
      display: contents;
      color: #ff0852;
      transition: 0.4s;
      &:hover {
        opacity: 0.6;
      }
    }
    p,
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 155%;

      font-feature-settings: "pnum" on, "lnum" on, "liga" off, "kern" off;
      color: #121212;
      margin-bottom: 1.8rem;
      // padding-left: 3rem;
      display: flex;
      flex-direction: column;
    }

    span {
      padding-left: 0;
      padding-bottom: 2.4rem;
      display: block;

      &::after {
        display: none;
      }
    }
    strong {
      display: contents;
    }
  }

  .newsimg {
    width: 100%;
    height: 73.8rem;
    object-fit: cover;
    border-radius: 3rem;
    margin-top: 3.8rem;
    margin-bottom: 5.6rem;
  }

  .newsdetailContent-span {
    border-left: 0.4rem solid #000;
    padding-left: 2.4rem !important;
    padding-bottom: 0 !important;
    display: block;
  }

  .newsContentBottom {
    margin-top: 6rem;
    border-top: 0.08rem solid #12121210;
    padding-top: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8.4rem;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 155%;

      color: #121212;
      margin-right: 2rem;
      display: block;
    }

    a,
    .copy {
      background: #f3f3f3;
      width: 4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.8rem;
      border-radius: 50%;

      svg {
        width: 1.9rem;
        height: 1.9rem;
      }
    }
  }

  .newsContentBottomCard {
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 3.6rem;
      line-height: 4.2rem;
      text-align: center;

      color: #121212;
      margin-bottom: 3.2rem;
    }
  }

  .paginationSwiper {
    .swiper-pagination {
      display: none;
    }
  }
  @media only screen and (max-width: 767.9px) {
    .newsDetailHeader {
      padding: 2rem 0 3.2rem;

      h5 {
        width: 100%;
        font-size: 2.4rem;
        line-height: 144%;
        margin-bottom: 1.2rem;
      }

      img {
        margin-top: 2.8rem;
        height: 30rem;
      }
    }

    .newsdetailContent {
      width: 100%;
      ol,
      ul {
        padding-left: 1rem;

        marker {
          display: none;
        }
      }
      h3 {
        font-size: 1.8rem;
        line-height: 144%;
      }

      p {
        font-size: 1.4rem;
        line-height: 155%;
      }

      span {
        font-size: 1.4rem;
        line-height: 155%;

        &:last-child {
          margin-bottom: 0;
        }
      }
      img {
        width: 100% !important;
        min-height: 30rem;
        height: 30rem !important;
      }
    }

    .newsimg {
      height: 30rem;
    }

    .newsContentBottom {
      padding: 1.4rem 0;
      display: flex;
      justify-content: start;
      margin-bottom: 6.4rem;
    }

    .paginationSwiper {
      .swiper-pagination {
        display: block;
      }
    }

    .newsContentBottomCard .title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 1.8rem;
    }
  }

  @media only screen and (max-width: 499.9px) {
    .newsDetailHeader {
      img {
        height: 20rem;
      }
    }

    .newsimg {
      height: 20rem;
      margin: 1.2rem 0 3.2rem;
    }
  }
}

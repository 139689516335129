.footer {
  background: #121212;
  padding: 6.2rem 0 4.2rem;

  .footer-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    // .footerContact {
    //     .footerCollapse {
    //         padding-top: 2.4rem;
    //     }
    // }
    .footerTopLeft {
      .footerCollapse {
        display: none;
        &:first-child {
          display: block;
        }
      }
    }
    ul {
      .Collapsible__trigger {
        font-style: normal;
        font-weight: 800;
        font-size: 1.8rem;
        line-height: 2.1rem;
        
        
        color: #fdfcfc;
        pointer-events: none;
      }

      li {
        margin-bottom: 1.4rem;
        display: flex;
        align-items: center;
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.6rem;
          
          color: #fdfcfc;
          opacity: 0.85;
          transition: 0.3s;
          border-bottom: 0.1rem solid transparent;
          width: fit-content;
          padding-bottom: 0.2rem;
          &:hover {
            opacity: 1;
            color: #ff2869;
            border-bottom: 0.1rem solid #ff2869;
          }
        }

        &:first-child {
          a {
            font-weight: 800;
            font-size: 1.8rem;
            line-height: 2.1rem;
            
            opacity: 1;
          }
        }
      }
    }

    .footerContact {
      .footer-title {
        margin-bottom: 2.8rem;
      }

      li {
        margin-bottom: 1rem;

        a,
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 140.9%;
          
          color: #fdfcfc;
          opacity: 1;
          display: flex;
          align-items: center;
          transition: 0.3s;
        }
        svg {
          margin-right: 0.8rem;
          width: 1.6rem;
          height: 1.6rem;
          g {
            opacity: 1;
          }
          path {
            transition: 0.3s;
          }
        }
        &:hover {
          a {
            color: #ff2869;
          }
        }
      }
    }
  }

  .footer-middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 4.6rem;
    position: relative;
    margin-bottom: 3.4rem;

    label {
      position: absolute;
      font-style: normal;
      font-weight: 800;
      font-size: 1.6rem;
      line-height: 1.9rem;
      
      
      color: #fdfcfc;
      margin-bottom: 1.3rem;
      display: block;
      top: 1.4rem;
      left: 0;
    }

    .footerSendMail {
      border: 0.1rem solid #fdfcfc;
      box-sizing: border-box;
      border-radius: 3rem;
      height: 5rem;
      position: relative;
      overflow: hidden;
      background: #121212;
      width: 38.5rem;

      input {
        height: 100%;
        border: 0;
        padding: 0 6rem 0 5.4rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.5rem;
        
        color: #ffffff !important;
        opacity: 1;
        width: -webkit-fill-available;
        background: transparent;

        &::placeholder {
          color: rgba(255, 255, 255, 0.45);
        }
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 5rem;
        padding: 0 3rem 0 0;
        background: transparent;
        border: none;

        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }

      .footerSendMailSvg {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 2.4rem;
        display: flex;
        align-items: center;

        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    .footer-social {
      display: flex;
      align-items: center;

      a {
        margin-right: 2.6rem;
        transition: 0.3s;

        svg {
          width: 4.2rem;
          height: 4.2rem;

          g {
            opacity: 1;
          }

          path {
            stroke: #fdfcfc;
            transition: 0.3s;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &:nth-child(3) {
          svg {
            path {
              fill: #fdfcfc;
              stroke: none;
            }
          }
        }
        &:hover {
          color: #ff2869;

          svg {
            path {
              stroke: #ff2869;
            }
          }
          &:nth-child(3) {
            svg {
              path {
                fill: #ff2869;
                stroke: none;
              }
            }
          }
        }
      }
    }

    .footer-store {
      display: flex;
      align-items: center;

      a {
        margin-right: 2.4rem;
        transition: 0.4s;
        svg {
          width: 19.8rem;
          height: 6rem;
        }

        &:last-child {
          margin-right: 0;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .footer-bottom-content,
  .footer-bottom-content a {
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 135%;
    
    color: #fcfcfc;
    display: flex;
    align-items: center;

    strong {
      display: flex;
      align-items: center;
    }

    span {
      
      opacity: 0.45;
      display: block;
      margin: 0 1.4rem 0 1.2rem;

      &.footer-span-line {
        position: relative;

        &::before {
          position: absolute;
          content: "";
          background: #fcfcfc;
          width: 0.1rem;
          height: 1.1rem;
          left: -1.2rem;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
    a {
      transition: 0.3s;
      &:hover {
        color: #ff2869;
      }
    }
  }

  @media only screen and (max-width: 767.9px) {
    padding-bottom: 6.4rem;

    .footer-top {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      ul {
        margin-bottom: 3.4rem;
        width: 48%;

        .Collapsible__trigger {
          font-size: 1.6rem;
          line-height: 1.9rem;
          pointer-events: all;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 1.6rem;
            height: 0.2rem;
            background-color: #fff;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0.7rem;
            margin: auto;
            width: 0.2rem;
            height: 1.6rem;
            background-color: #fff;
            transition: 0.3s all;
          }

          &.is-closed {
            &::after {
              right: 0.4rem;
              transform: rotate(90deg);
            }
          }

          &.is-open + .Collapsible__contentOuter {
            height: 0px !important;
            transition: height 400ms linear 0s !important;
            overflow: hidden !important;
          }

          &.is-closed + .Collapsible__contentOuter {
            height: auto !important;
            transition: none 0s ease 0s !important;
            overflow: hidden !important;
          }
        }

        &.footerContact {
          border-bottom: 0.1rem solid #3d3d3d;
          padding-bottom: 2rem;
        }
      }
      .footerContact {
        width: 100%;
      }
    }

    .footer-bottom {
      .footer-bottom-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        strong {
          margin-bottom: 2rem;

          span {
            font-size: 1.2rem;
            line-height: 135%;

            &::before {
              display: none;
            }
          }
        }
      }
    }

    .footer-middle {
      display: flex;
      flex-direction: column;

      .footer-email-send {
        width: 100%;

        .footerSendMail {
          margin-bottom: 3rem;
          width: 100%;
        }
      }

      .footer-social {
        display: flex;
        align-items: center;
        width: 80%;
        justify-content: space-around;
        margin-bottom: 2.8rem;

        a {
          svg {
            width: 4.1rem;
            height: 4.1rem;
          }
        }
      }
      .footer-bottom-content {
        strong {
          font-size: 1rem;
          span {
            word-break: break-all;
            margin-right: 0;
            font-size: 1rem;
          }
        }
      }
      .footer-store {
        width: 95%;
        justify-content: center;

        a {
          margin-right: 0;

          svg {
            max-width: 42%;
            min-width: 15rem;
            height: auto;
          }
        }
      }
    }
  }
}

.topSlider {
  background-color: #f6f6f6;
  height: 43.5rem;
  .swiper-slide {
    height: 43.5rem;
    background-repeat: no-repeat !important;
    background-size: cover;
    background-size: cover !important;
    background-position: right !important;

    a.deactive {
      pointer-events: none;
    }
    .slider-content-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 43.5rem;

      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 6.4rem;
        line-height: 7.5rem;
        text-align: center;

        color: #000000;
        margin-bottom: 0.8rem;
      }

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 4.2rem;
        text-align: center;

        color: #000000;
        margin-bottom: 3.2rem;
      }

      button,
      a {
        background: #121212;
        border-radius: 3rem;
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.1rem;

        color: #fdfcfc;
        border: none;
        height: 6rem;
        padding: 0 4.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        &:hover {
          background-color: #ff0852;
          color: #fff;
        }
      }
    }
  }
  @media only screen and (min-width: 1600.9px) {
    height: 60rem;

    .swiper-slide {
      height: 60rem;
      .slider-content-center {
        height: 60rem;
      }
    }
  }

  .swiper-pagination-bullet {
    border: 0.07rem solid #868686;
    box-sizing: border-box;
    border-radius: 3rem;
    width: 1.8rem;
    height: 0.4rem;
    background: transparent;
    transition: 0.3s;

    &:hover {
      background: #ff0852;
      border: 0.07rem solid #ff0852;
      opacity: 1;
    }

    &:focus {
      background-color: #700022;
      border: 0.07rem solid #700022;
    }
  }

  .swiper-pagination-bullet-active {
    background: #121212;
    width: 5.4rem;
    border: 0.07rem solid #121212;
    transition: 0.3s;

    &:hover {
      background: #121212;
      border: 0.07rem solid #121212;
    }
  }

  @media only screen and (max-width: 767.9px) {
    height: 29rem;
    .swiper-slide,
    .slider-content-center {
      height: 30rem !important;
    }

    .swiper-slide {
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position-x: right !important;
      background-position-y: bottom !important;

      .slider-content-center {
        width: 40%;

        h4 {
          font-size: 2.8rem;
          margin-bottom: 0.2rem;
          line-height: 3.2rem;
        }

        p {
          font-size: 1.4rem;
          line-height: 1.3rem;
          margin-bottom: 1.6rem;
        }

        button,
        a {
          height: 4rem;
          font-size: 1.8rem;
          line-height: 2rem;
          padding: 0 2.8rem;
        }
      }
    }

    .swiper-pagination-bullet {
      width: 1.2rem;
      height: 0.2rem;

      &.swiper-pagination-bullet-active {
        height: 0.2rem;
        width: 3.4rem;
      }
    }
  }

  @media only screen and (max-width: 449.9px) {
    // margin-top: 1.2rem;
    height: 12rem;

    .swiper-slide,
    .slider-content-center {
      height: 12.5rem !important;
    }

    .swiper-slide {
      .slider-content-center {
        width: 50%;

        h4 {
          font-size: 1.8rem;
          line-height: 2.2rem;
        }

        p {
          font-size: 1rem;
          margin-bottom: 0.8rem;
        }

        button,
        a {
          height: 3rem;
          font-size: 1.1rem;
          line-height: 1.3rem;
          padding: 0 2rem;
        }
      }
    }
  }
}

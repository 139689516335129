.authentication {
  .breadCrumb {
    margin-top: 1.4rem;

    a,
    span {
      color: #fff;
      padding-bottom: 0;
    }

    svg {
      path {
        stroke: #fff;
      }
    }
  }

  .auth-content {
    width: 100%;
    height: 63.4rem;
    display: flex;
    align-items: flex-start;
    // margin-top: -3.4rem;

    .left {
      width: 45%;
      height: 100%;
      background: #121212;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      a {
        margin-bottom: 3.2rem;

        svg {
          width: 20.6rem;
          height: 12.3rem;

          path {
            fill: #fff;
          }
        }
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 164.4%;
        
        color: #fdfcfc;
      }
    }

    .right {
      // width: 55%;
      height: 100%;
      height: 63.4rem;
      background: #f6f5f5;
      // background: red;
      width: calc(55% + 1rem);
      display: flex;
      justify-content: center;
      flex-direction: column;

      .auth-content-child {
        width: 65%;
        padding: 0 0 0 16.4rem;
      }

      .authContentChildHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3.2rem;

        a {
          font-style: normal;
          font-weight: 600;
          font-size: 3.2rem;
          line-height: 3.8rem;
          
          
          color: #121212;
          opacity: 0.35;

          &.active {
            opacity: 1;
          }

          &.Login {
            margin-right: 1.6rem;
          }

          &.Register {
            padding-left: 1.6rem;
            border-left: 0.1rem solid rgba(18, 18, 18, 0.35);
          }
        }
      }

      .authContentFooter-guest {
        display: flex;
        align-items: center;
        justify-content: center;

        .contunie-guest {
          text-align: center;
          font-style: normal;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 1.9rem;
          
          color: #121212;
          border-bottom: 0.1rem solid #000;
        }
      }
    }
  }

  &.register .auth-content-child {
    padding-top: 5.2rem !important;
  }

  @media only screen and (max-width: 767.9px) {
    .left {
      display: none !important;
    }

    .auth-content {
      margin-top: 0;

      .right {
        width: 100% !important;

        .auth-content-child {
          padding: 6.4rem 2.4rem !important;
          width: -webkit-fill-available;
        }

        .authContentChildHeader {
          margin-bottom: 2.4rem;

          a {
            font-size: 2rem;
            line-height: 2.4rem;
          }
        }
      }

      .authContentFooterChild {
        margin-bottom: 2.4rem;
      }
    }
  }
}

.collection {
  padding: 1.2rem 0 4.8rem;
  width: 100%;

  .collectionHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 3.6rem 0 5.6rem;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.6rem;
      text-align: center;
      
      
      color: #121212;
      margin-bottom: 0.8rem;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.3rem;
      text-align: center;
      color: #121212;
    }
  }

  .collectionImgCard {
    position: relative;
    padding-top: 100%;
    width: 100%;
  }

  .collectionImgCardChild {
    background: #f7f7f8;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;

    img {
      width: 100%;
      height: 70%;
      object-fit: scale-down;
    }
  }

  .collectionTextParent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .collectionText {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70%;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 155%;
        text-align: center;
        color: rgba(18, 18, 18, 0.86);
        margin-bottom: 3.2rem;

        span {
          font-weight: 600;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  h6.title {
    font-style: normal;
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 5.6rem;
    text-align: center;
    
    
    color: #121212;
    margin-bottom: 2.4rem;
  }

  .container-second {
    .row {
      &:nth-child(even) {
        grid-auto-flow: dense;
        direction: rtl;

        p {
          direction: ltr;
        }
      }
    }
  }
  .collectionCard {
    margin-bottom: 2.6rem;
  }

  @media only screen and (max-width: 767.9px) {
    .collectionHeader {
      padding: 1.4rem 0 2.4rem;
      margin: 0;

      h4 {
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-bottom: 1.2rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
    }

    .collectionTextParent {
      .collectionText {
        width: 100%;

        h6.title {
          font-size: 2.4rem;
          line-height: 2.8rem;
          margin-bottom: 1.8rem;
        }

        p {
          font-size: 1.4rem;
          line-height: 155%;
          margin-bottom: 1.8rem;
        }
      }
    }
  }
}

.collectionBottom {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 5.6rem;
    text-align: center;
    
    
    color: #121212;
    margin-bottom: 2.4rem;
  }
}

.collections {
  padding-top: 4.8rem;
  margin-bottom: 4.8rem;

  .title {
    margin-bottom: 3.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 3.6rem;
      line-height: 4.2rem;
      text-align: center;
      
      
      color: #121212;
      margin-bottom: 0.8rem;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 155.5%;
      text-align: center;
      
      color: #121212;
      width: 50%;
      text-align: center;
    }
  }

  .category-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 30rem 30rem;
    .category-box-child {
      position: relative;
      display: block;
      h4 {
        color: #000;
        font-size: 2.8rem;
        font-style: normal;
        font-weight: 600;
        left: 2.4rem;
        
        line-height: 140%;
        position: absolute;
        
        top: 2.4rem;
        width: 50%;
      }

      a {
        height: 100%;
      }

      img {
        bottom: 0;
        bottom: 1rem !important;
        height: 70% !important;
        object-fit: contain;
        position: absolute;
        right: 0;
        right: 1rem !important;
        width: auto;
      }

      &:nth-child(1) {
        grid-column: 1;
        background-color: #eeedf0;
      }

      &:nth-child(2) {
        grid-column: 2;
        background: #f3eae1;
      }

      &:nth-child(3) {
        grid-column: 3;
        background: #eff4f6;
      }

      &:nth-child(4) {
        grid-column: 4;
        background: #e4ddea;
      }

      &:nth-child(5) {
        grid-column: 1;
        grid-row: 2;
        background: #f8f4df;
      }
      &:nth-child(6) {
        grid-column: 2;
        grid-row: 2;
        background: #e0e0e2;
      }
      &:nth-child(7) {
        grid-column: 3;
        grid-row: 2;
        background: #f1e5e5;
      }
      &:nth-child(8) {
        grid-column: 4;
        grid-row: 2;
        background: #c3fbd8;
      }
    }
  }

  .category-box-child {
    overflow: hidden;
  }

  .category-box-child img {
    transition: transform 0.5s ease;
  }

  .category-box-child:hover img {
    transform: scale(1.1);
  }

  @media only screen and (max-width: 767.9px) {
    margin-bottom: 0;

    .title {
      margin-bottom: 2.4rem;

      h4 {
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-bottom: 0.8rem;
      }

      p {
        font-size: 1.4rem;
        line-height: 155.5%;
        width: 95%;
      }
    }

    .category-box {
      display: flex;
      flex-direction: column;

      .category-box-child {
        height: 30rem !important;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        overflow: hidden;

        h4 {
          font-size: 2.4rem;
          line-height: 140%;
          // position: static;
          left: 3rem;
          top: 4rem;
          width: 48%;
          right: auto !important;
        }

        img {
          // position: static;
          width: 50%;
          height: 20rem !important;
          right: -2rem;
          bottom: 1rem;
        }
      }
    }
  }
}

.headerMiddle {
  background-color: #fdfcfc;
  transition: 1s all ease;

  .headerMiddleParent {
    height: 15rem;
  }

  .allParfumeSearch {
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 155%;
    color: #121212;
    opacity: 0.9;
    margin-top: 1.4rem;
    border-bottom: 0.08rem solid #121212;
    width: fit-content;
    margin-left: 3.2rem;
    transition: 0.3s;
    &:hover {
      color: #ff0852;
      border-bottom: 0.08rem solid #ff0852;
    }
  }
  .headerMiddleChild {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.2rem 0 1.2rem;

    .logo {
      svg {
        width: 12.2rem;
        height: 12rem;
      }
      &.deactive {
        pointer-events: none;
        cursor: none;
      }
    }

    .headerMiddleChild {
      padding: 0;
      width: 100%;
    }

    .headerSearch {
      width: 50%;
    }

    .searchForm {
      border: 0.15rem solid #656565;
      box-sizing: border-box;
      border-radius: 6.5rem;
      height: 4.8rem;
      position: relative;
      padding: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      // max-width: 49rem;
      width: 100%;

      input {
        width: 100%;
        height: 4.8rem;
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;

        color: #121212 !important;
        padding: 0;
        margin: 0;
        border: none;
        padding: 0 4.2rem;
      }

      a {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: transparent;
        border: 0;
        padding: 0 3.2rem 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 1.6rem;
          height: 1.6rem;

          path {
            transition: 0.3s;
          }
        }
        &:hover {
          svg {
            path {
              fill: #ff0852;
            }
          }
        }
      }
    }

    .headerMiddleContentLink {
      display: flex;
      align-items: center;

      a {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 2.8rem;

        color: #000000;
        padding: 0;
        white-space: nowrap;
        transition: 0.3s;
        &:first-child {
          border-right: 0.04rem solid rgba(18, 18, 18, 0.25);
          padding-right: 1.6rem;
        }

        &:last-child {
          margin-left: 1.6rem;
          border-bottom: 0.1rem solid #121212;

          &:hover {
            color: #ff0852;
            border-bottom: 0.1rem solid #ff0852;
          }
        }
      }

      svg {
        width: 3.1rem;
        height: 3.1rem;
      }

      .whatsapp-button {
        svg {
          path {
            transition: 0.3s;
          }
        }

        &:hover {
          svg {
            path {
              fill: #25cb25;
            }
          }
        }

        &:focus {
          svg {
            path {
              fill: #159115;
            }
          }
        }
      }
    }

    .headerMiddleAccount {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        margin-left: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 1.3rem;
          line-height: 1.5rem;

          color: #121212;
          display: block;
          margin-top: 0.3rem;
          white-space: nowrap;
          transition: 0.3s;
        }

        svg {
          width: 4.6rem;
          height: 4.6rem;

          path {
            transition: 0.3s;
          }
        }

        &:hover {
          span {
            color: #ff0852;
          }

          svg {
            path {
              stroke: #ff0852;
            }
          }
        }

        &:focus {
          span {
            color: #700022;
          }

          svg {
            path {
              stroke: #700022;
            }
          }
        }
      }
    }
  }
  &.is-sticky {
    animation: 400ms ease-in-out 0.3s normal none 0.5 running fadeInDown;
    transition: all 0.3s;
    // box-shadow: 0 2rem 2rem rgba(125, 125, 125, 0.1);
    border-bottom: 0.1rem solid rgba(18, 18, 18, 0.15);
    width: 100%;
    z-index: 12;
    background-color: #ffffff !important;
    position: fixed;
    top: 0;
    opacity: 1;
    // @keyframes fadeInDown {
    //   0% {
    //     transform: translateY(-2rem);
    //     opacity: 1;
    //   }
    //   100% {
    //     opacity: 1;
    //     transform: translateY(0);
    //   }
    // }
    .currentSpan {
      font-size: 1.4rem;
      display: none;
      width: 1.6rem;
      height: 1.6rem;
      background: transparent;
      color: #15971a !important;
    }
    .logo,
    .logo svg {
      width: 5.2rem;
      height: 5rem;
    }
    .headerMiddleChild {
      padding: 0.8rem 0;
      .headerMiddleChild {
        padding: 0;
        width: 75%;
        justify-content: space-between;
      }
      .headerSearch {
        width: 45%;
        .searchForm {
          height: 3.6rem;
          input {
            font-size: 1.4rem;
          }
        }
        .searchPopup {
          top: 5rem;
        }
      }
      .headerMiddleContentLink {
        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
        a {
          font-size: 1.8rem;
          display: flex;
          align-items: center;
        }
      }
      .headerMiddleAccount {
        a {
          margin-left: 1.6rem;
          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
          span {
            display: none;
          }
        }
      }
    }
  }
  .notFoundSearch {
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;

    color: #ff0852;
  }
  .searchProduct {
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
    opacity: 1 !important;

    .img {
      background: #f3f3f3;
      border-radius: 1.5rem;
      height: 6.4rem;
      width: 6.4rem;
      min-width: 6.4rem;
      margin-right: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 70%;
        height: 70%;
        object-fit: contain;
      }
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 155%;

      color: #121212;
      opacity: 0.9;
    }
  }

  .headerSearch {
    position: relative;
    margin: 0 3.4rem;

    .searchPopup {
      position: absolute;
      top: 6.4rem;
      left: 0;
      background: #fff;
      box-shadow: 0 3rem 7.4rem rgba(148, 111, 111, 0.15);
      border-radius: 1.5rem;
      width: 100%;
      max-height: 55rem;
      z-index: 10;

      .searchPopupInner {
        padding: 3.6rem 4.2rem;

        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 1.7rem;
          line-height: 2rem;

          color: #000000;
          display: none;
          margin-bottom: 1.6rem;
        }

        .searchResult {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: auto;

          .searchResultChild {
            overflow: auto;
            max-height: 40rem;

            &::-webkit-scrollbar {
              width: 0.4rem;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              border-right: 0.2rem solid rgba(18, 18, 18, 0.1);
              width: 0;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #ff0852;
              border-radius: 1rem;
            }
          }
        }

        .searchCategory {
          a {
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.8rem;

            color: rgba(0, 0, 0, 0.7);
            margin-bottom: 2rem;
            transition: 0.3s;

            &:hover {
              color: #ff0852;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .cartCurrent {
    position: relative;

    .currentSpan {
      position: absolute;
      right: 0;
      background: #15971a;
      border-radius: 50%;
      top: -1rem;
      right: -1rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex !important;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      text-align: center !important;
      color: #fdfcfc !important;
    }
  }

  .menuButton,
  .searchMobile,
  .headerMiddleMenu,
  .searchMobilePopup {
    display: none;
  }

  .searchMobile {
    display: none;
  }

  .headerMiddleParent {
    height: 14rem;
  }

  @media only screen and (max-width: 767.9px) {
    .allParfumeSearch {
      display: none;
    }
    .headerMiddleParent {
      height: auto;
    }

    .headerMiddleChild {
      padding: 1.2rem 0;

      .logo {
        svg {
          width: 5.2rem;
          height: 5rem;
          margin-right: 0;
        }
      }

      .headerMiddleContentLink,
      .headerMiddleAccount,
      .headerSearch,
      .headerMiddleChild {
        display: none;
      }

      .menuButton,
      .searchMobile {
        padding: 0;
        border: none;
        display: block;
        cursor: pointer;
        background-color: transparent;

        svg {
          width: 4rem;
          height: 4rem;
        }
      }

      .searchMobile {
        svg {
          width: 2.8rem;
          height: 2.8rem;

          path {
            fill: #000000;
          }
        }
      }
    }

    .headerMiddleMenu {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #fdfcfc;
      z-index: 10;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      .headerMiddleMenuTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 8.6rem;
        padding: 0 1.8rem 0 1rem;

        .xButton {
          background-color: transparent;
          padding: 0;
          border: none;

          svg {
            width: 4.2rem;
            height: 4.2rem;
          }
        }

        .logo {
          svg {
            width: 8.6rem;
            height: 6rem;
          }
        }

        .menuLang {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button {
            font-style: normal;
            font-weight: 600;
            font-size: 1.3rem;
            line-height: 1.5rem;

            color: #121212;
            padding: 0;
            border: none;
            background-color: transparent;

            &:last-child {
              margin-left: 2.2rem;
            }
          }
        }
      }

      .headerMiddleMenuBody {
        padding: 0 2rem 6rem 2rem;
        .headerMiddleMenuBodyInner {
          padding: 0;
        }
        ul {
          border-bottom: 0.08rem solid rgba(18, 18, 18, 0.07);

          li {
            a,
            button {
              font-style: normal;
              font-weight: 600;
              font-size: 2rem;
              line-height: 2.3rem;

              color: #121212;
              background-color: transparent;
              border: none;
              padding: 1.4rem 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              svg {
                transform: rotate(-90deg);
              }
            }

            .headerMiddleMenuBodyInner {
              height: 0;
              opacity: 0;
              display: none;
              a {
                border: none;
                font-size: 1.4rem !important;

                svg {
                  display: none;
                }
              }
            }

            &:hover {
              .headerMiddleMenuBodyInner {
                height: auto;
                opacity: 1;
                display: block;
              }
            }
          }
        }

        .middleMenuBody {
          padding: 1.4rem 0;
          border-bottom: 0.08rem solid rgba(18, 18, 18, 0.07);

          a {
            font-style: normal;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 2.1rem;

            color: #121212;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 1.2rem 0;
            width: fit-content !important;

            svg {
              width: 2.8rem;
              height: 2.8rem;
              margin-right: 1.8rem;
            }

            .currentSpan {
              left: 1.6rem;
              top: 0.6rem;
              width: 1.8rem;
              height: 1.8rem;
              font-size: 0.9rem !important;
              line-height: 1.1rem !important;
            }
          }
        }

        .middleMenuBodyWp {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2rem 0;

          a {
            font-style: normal;
            font-weight: 600;
            font-size: 2.4rem;
            line-height: 2.8rem;

            color: #000000;
            border-bottom: 0.1rem solid #121212;

            &:first-child {
              padding: 0 2rem;
              border-bottom: none;
              border-right: 0.04rem solid rgba(18, 18, 18, 0.25);
              margin-right: 2rem;

              svg {
                width: 3.1rem;
                height: 3.1rem;
              }
            }
          }
        }

        .middleMenuBodySocial {
          display: flex;
          align-items: center;
          width: 90%;
          justify-content: space-between;
          padding-top: 1.8rem;

          a {
            svg {
              width: 3.1rem;
              height: 3.1rem;
              opacity: 0.7;
              transition: 0.3s;
            }

            &:hover {
              svg {
                opacity: 1;
              }
            }
          }
        }
      }

      &.headerMiddleCategory {
        ul {
          border: none !important;
        }
      }
    }

    .searchMobilePopup {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background-color: #fdfcfc;
      z-index: 10;

      .searchPopupInner {
        padding: 2.8rem 2rem;

        .searchPopupInnerHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4rem;

          form {
            border: 0.15rem solid #656565;
            box-sizing: border-box;
            border-radius: 6.5rem;
            position: relative;
            width: 100%;
            height: 4.2rem;
            overflow: hidden;

            input {
              width: -webkit-fill-available;
              font-style: normal;
              font-weight: 500;
              font-size: 1.6rem;
              line-height: 1.9rem;

              color: #000000;
              height: 100%;
              border: none;
              padding: 0 2.4rem;

              &::-webkit-search-cancel-button {
                position: absolute;
                right: 0.3rem;
                -webkit-appearance: none;
                height: 2rem;
                width: 2rem;
                border-radius: 50%;
                background-color: #fff;
                background-image: url("../../../assets/xx.svg");
                background-repeat: no-repeat;
                z-index: 9;
              }
            }

            button {
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              height: 4.2rem;
              width: 3.6rem;
              background-color: transparent;
              border: none;

              svg {
                width: 1.6rem;
                height: 1.6rem;
              }
            }
          }

          button.close {
            margin-left: 1.6rem;
            font-style: normal;
            font-weight: normal;
            font-size: 1.3rem;
            line-height: 1.5rem;

            color: #121212;
            opacity: 0.7;
            border: none;
            background-color: transparent;
            padding: none;
            white-space: nowrap;
          }
        }

        .searchPopupInnerBody {
          padding: 0;

          .title {
            font-style: normal;
            font-weight: 600;
            font-size: 1.7rem;
            line-height: 2rem;

            color: #000000;
            margin-bottom: 3rem;
          }

          a {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.9rem;

            color: #000000;
            opacity: 0.7;
            margin-bottom: 2.4rem;
            padding-left: 0.8rem;
          }
        }
      }
    }

    .searchProduct {
      opacity: 1 !important;
      margin-bottom: 0.6rem !important;
      padding-left: 0 !important;
      .img {
        height: 7.6rem;
        width: 7.8rem;
      }

      p {
        font-size: 1.5rem;
        line-height: 155%;
      }
    }

    .more-search {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        padding-left: 0 !important;
        margin: 0 !important;
        margin-top: 2.4rem !important;
        opacity: 1 !important;
        font-weight: 600 !important;
        font-size: 1.4rem !important;
        line-height: 1.6rem;
      }
    }
  }

  @media only screen and (max-width: 1023.9px) {
    background: #fdfcfc;

    .searchForm {
      width: 100% !important;
    }
  }
}

.find {
    padding: 1.2rem 0 3.2rem;

    .findSection {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 13rem 0 20rem;

        h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 4.8rem;
            line-height: 5.6rem;
            text-align: center;
            
            
            color: #121212;
            margin-bottom: .8rem;
        }

        p {
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            line-height: 3.3rem;
            text-align: center;
            
            color: #121212;
            opacity: 0.86;
            width: 60%;
            margin-bottom: 2.8rem;
        }

        .blackButton {
            width: 19.4rem;
        }
    }

    .findStepSection {
        padding: 3.6rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .findSection {
            padding: 8.2rem 0 0;
        }

        .findSectionChild {
            margin-bottom: 6.4rem;
        }

        h5 {
            margin-bottom: 2.6rem;
            font-style: normal;
            font-weight: 600;
            font-size: 4.8rem;
            line-height: 5.6rem;
            text-align: center;
            
            
            color: #121212;
        }

        .step-p {
            font-style: normal;
            font-weight: normal;
            font-size: 2rem;
            line-height: 3.3rem;
            
            color: #121212;
            opacity: 0.86;
        }

        .findPrev {
            padding: 0;
            background-color: transparent;
            border: none;

            svg {
                width: 6rem;
                height: 5.6rem;
            }
        }

        .findNext {
            font-size: 1.8rem;
            height: 5.6rem;
            padding: 0 5rem;
            margin-left: 1.6rem;
            width: fit-content;
        }

        .stepButtons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 8rem;
        }

    }

    .stepOneCheckbox {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    label {
        margin-bottom: 0;
        margin-left: 4rem;
        padding-left: 4.6rem;

        &:first-child {
            margin-left: 0;
        }

        input,
        span {
            height: 3.5rem;
        }

        input {
            z-index: 8;
            position: absolute;
            left: 0;
            top: 1.6rem !important;
        }


        span {
            display: flex;
            align-items: center;
            font-size: 2rem;
        }

        .checkmark {
            width: 3.5rem;
            height: 3.5rem;

            &::after {
                width: 2.1rem;
                height: 2.1rem;
            }
        }
    }

    .filterRadioImg {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-left: 0;

        .filterRadioFind {
            position: relative;
            padding-left: 4.6rem;
            padding-top: 1.2rem;

            input {
                top: 0;
            }

            .checkmark {
                top: 1.2rem;
            }
        }

        img {
            height: 41.5rem;
            min-width: 30rem;
            max-width: 30rem;
        }
    }

    .findImgCard {
        margin-bottom: 4.8rem;
    }

    .productCard {
        margin-bottom: 3.2rem;
    }

    .stepFour {
        img {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
        }
    }

    @media only screen and (max-width: 767.9px) {
        .findSection {
            padding: 14rem 0 0;

            h5 {
                font-size: 2.4rem;
                line-height: 2.8rem;
                margin-bottom: 1.8rem;
            }

            p {
                font-size: 1.4rem;
                line-height: 155%;
                width: 100%;
            }

            .blackButton {
                margin-top: 20rem;
                font-size: 1.6rem;
                line-height: 1.9rem;
                height: 5.2rem;
                width: 100%;
            }
        }

        .findStepSection {
            form {
                width: 100%;
            }

            .findSection {
                align-items: flex-start;
                padding: 2.4rem 0 6.4rem;

                .stepOneCheckbox {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .filterRadio {
                        margin-left: 0;
                        padding-left: 3.2rem;

                        input {
                            top: 0 !important;
                        }

                        span {
                            font-size: 1.8rem;
                            line-height: 3.3rem;
                            margin-top: -.2rem;
                        }

                        .checkmark {
                            width: 2.6rem;
                            height: 2.6rem;
                            margin-top: .4rem;

                            &::after {
                                width: 1.4rem;
                                height: 1.4rem;
                            }
                        }
                    }
                }

                h5 {
                    font-size: 2.4rem;
                    line-height: 2.8rem;
                    text-align: left;
                }
            }

            // .filterRadioImg {
            //     width: 50%;
            // }

            .stepButtons {
                width: 100%;
                margin-top: 0;

                button {
                    width: 100%;
                    height: 5.2rem;
                    margin-left: 0;
                }

                .findPrev {
                    margin-right: 1.2rem;
                    width: 5.2rem;
                    height: 5.2rem;

                    svg {
                        width: 5.2rem;
                        height: 5.2rem;
                    }
                }
            }

            .stepTwo {
                width: 100%;

                h5 {
                    text-align: center;
                }

                .stepOneCheckbox {
                    display: flex;
                    flex-direction: row;
                    padding: 0 .8rem;

                    .filterRadioImg {
                        width: 50%;
                        padding-left: 0;

                        &:first-child {
                            margin-right: 2.4rem;
                        }
                    }

                    img {
                        height: auto;
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                        min-height: 22.3rem;
                    }
                }
            }

            .stepFour {
                width: 100%;

                h5 {
                    text-align: center;
                }

                img {
                    min-height: 22.4rem;
                    height: auto;
                }
            }

            .stepFive {
                width: 100%;

                h5 {
                    text-align: center;
                }
            }
        }
    }
}
.cart {
  padding: 1.2rem 0 10rem;
  background-color: #fdfcfc;

  .filterRadio {
    padding-left: 3.6rem !important;

    span {
      height: 3rem !important;
      display: flex;
      align-items: center;
    }

    .checkmark {
      height: 2.9rem !important;
      width: 2.9rem !important;
    }

    .checkmark:after {
      width: 1.4rem !important;
      height: 1.4rem !important;
    }
  }
  textarea {
    background: #f3f3f3;
  }
  .address {
    overflow: auto;
    max-height: 44rem;
    margin-bottom: 4.2rem;
    padding: 0;
    padding-right: 1.6rem;
    .addressCardInnerBottom {
      padding: 0 1.2rem 0 3.6rem !important;

      svg {
        width: 2rem;
        height: 2rem;
      }
      p {
        font-size: 1.4rem;
      }
    }
    /* width */
    &::-webkit-scrollbar {
      width: 0.2rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-right: 0.1rem solid rgba(18, 18, 18, 0.1);
      width: 0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ff0852;
      border-radius: 1rem;
    }
  }

  h5.title {
    font-style: normal;
    font-weight: 800;
    font-size: 3.2rem;
    line-height: 3.8rem;
    text-align: center;

    color: #121212;
    margin-bottom: 1rem;
  }

  .cart-container {
    padding: 3.4rem 0;

    .cart-header {
      margin-bottom: 3.2rem;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.3rem;
        text-align: center;

        color: #121212;
        opacity: 0.36;
        display: block;
      }
    }

    .cartTable {
      .react-tabs__tab-list {
        display: flex;
        justify-content: space-between;
        border-bottom: 0.1rem solid rgba(18, 18, 18, 0.35);

        .react-tabs__tab {
          font-style: normal;
          font-weight: 600;
          font-size: 2rem;
          line-height: 2.3rem;
          text-align: center;

          color: rgba(18, 18, 18, 0.35);
          border: none;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: none;
          outline: none;
          padding: 2.8rem 0;
          background-color: transparent;

          &.react-tabs__tab--selected {
            border-bottom: 0.4rem solid #121212;
            color: #121212;
            padding: 2.8rem 0 2.4rem;
          }
        }
      }
    }
    .resp-width {
      width: -webkit-fill-available;
    }
    .cartTableContent {
      // background-color: red;
      // display: flex;
      // align-items: flex-start;
      padding-top: 2rem;
      padding-bottom: 0 !important;

      // .react-tabs__tab-panel {
      //   width: 45%;
      // }

      .cartRight {
        // width: 55%;
        padding-left: 3.2rem;

        .cartRightParent {
          max-height: 82rem;
          overflow: auto;
          border-bottom: 0.1rem solid #ebeaea;
          margin-bottom: 1.2rem;

          .cartRightChild {
            overflow: hidden;
          }

          /* width */
          &::-webkit-scrollbar {
            width: 0.2rem;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            border-right: 0.1rem solid rgba(18, 18, 18, 0.1);
            width: 0;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #ff0852;
            border-radius: 1rem;
          }
        }

        .cartTotal {
          background: #ffffff;
          border: 0.1rem solid #f5f5f5;
          box-sizing: border-box;
          border-radius: 1.5rem;
          padding: 0 3.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 8.8rem;
          margin-bottom: 1.6rem;

          p {
            display: flex;
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 1.6rem;

            color: rgba(18, 18, 18, 0.75);
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
            }

            span {
              display: flex;
              margin-left: 3.4rem;
              font-style: normal;
              font-weight: 600;
              font-size: 1.6rem;
              line-height: 1.9rem;

              color: #121212;
              align-items: center;

              svg {
                width: 1.2rem;
                height: 0.8rem;
              }
            }
          }

          .CarttotalPrice {
            width: 40%;
            border-right: 0.1rem solid #e7e7e7;
          }

          .cartTotalChild {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60%;
            // padding-left: 5rem;

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 1.7rem;
              line-height: 2.8rem;

              color: rgba(18, 18, 18, 0.75);

              span {
                font-size: 2.4rem;
                line-height: 2.8rem;
                display: flex;
                align-items: center;
                margin-left: 1.4rem;

                svg {
                  width: 2.4rem;
                  height: 1.8rem;
                }
              }
            }
          }
        }
      }
    }

    .newAccountTitleChild {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    .filterCheckbox {
      padding-left: 4.2rem;

      span {
        height: 3rem;
      }

      input {
        height: 3.3rem;
      }

      .checkmark {
        width: 3rem;
        height: 3rem;
      }
    }
    .paymentLabel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      label {
        margin-left: 0 !important;
      }
    } 
    .delivery_agree {
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 2rem;
        line-height: 1.6rem;
        margin-bottom: 2.3rem;
        display: block;
        color: rgba(18, 18, 18, 0.75);
      }
    }
    .newAccount {
      width: 100%;
      .newAccountContent {
        margin-bottom: 5.6rem;

        .address {
          padding-right: 0;
        }
        .newAccountTitle {
          font-style: normal;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 2.1rem;

          color: #121212;
          display: flex;
          align-items: center;
          margin-bottom: 2rem;

          span {
            border: 0.1rem solid #ff0852;
            box-sizing: border-box;
            width: 3.6rem;
            height: 3.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 2.3rem;
            text-align: center;

            color: #ff0852;
            border-radius: 50%;
            margin-right: 1rem;
          }

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 1.3rem;
            line-height: 1.5rem;

            color: #000000;
            opacity: 0.6;
            margin-left: 1.2rem;
          }
        }

        .filterRadio {
          margin-left: 4.8rem;
          margin-bottom: 1.2rem;
        }

        .save-Info {
          margin-top: 4.4rem;
          display: none;
          label {
            margin-bottom: 1rem;

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 2rem;
              line-height: 3.3rem;

              color: #121212;
              margin-top: 0;
            }
          }

          .saveInfoChild {
            padding-left: 3.2rem;
            width: 70%;
            margin-bottom: 3.2rem;
            p {
              font-style: normal;
              font-weight: 500;
              font-size: 1.3rem;
              line-height: 155%;

              color: rgba(0, 0, 0, 0.6);
              margin-bottom: 0.6rem;
            }

            a {
              font-style: normal;
              font-weight: 600;
              font-size: 1.3rem;
              line-height: 164.4%;
              color: #121212;
              border-bottom: 0.1rem solid #121212;
              padding-bottom: 0.3rem;
              width: fit-content;
            }
          }
        }
      }
      .toLogin {
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 164.4%;
        color: #121212;
        border-bottom: 0.1rem solid #121212;
        // padding-bottom: 0.3rem;
        width: -moz-fit-content;
        width: fit-content;
        transition: 0.3s;

        &:hover {
          color: #ff0852;
          border-bottom: 0.1rem solid #ff0852;
        }
      }

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.1rem;

        color: #121212;
        margin-bottom: 2.4rem;
      }

      .input-group {
        margin-bottom: 1.8rem;

        input {
          background: #f3f3f3;
          font-weight: 500;

          &::placeholder {
            text-transform: capitalize;
            color: rgba(18, 18, 18, 0.6) !important;
          }
        }
      }
    }
  }

  .orderComplated {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.6rem;

      color: rgba(18, 18, 18, 0.75);
      border-bottom: 0.1rem solid rgba(0, 0, 0, 0.7);
      transition: 0.3s;

      &:hover {
        color: #121212;
        border-bottom: 0.1rem solid #000;
      }
    }
  }

  .cartEmpty {
    background-color: #fdfcfc;
    width: 100%;
    border-radius: 1.5rem;

    .cartEptyContent {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding-top: 7.4rem;
      padding-bottom: 6.4rem;

      a {
        margin-bottom: 2.8rem;
        display: block;

        svg {
          width: 22.4rem;
          height: 15.4rem;
        }
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 2rem;
        line-height: 2.4rem;

        color: #12121296;
        margin-bottom: 3.2rem;
      }
    }

    .cartEmptyButton {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: fit-content;
      padding: 0 4.2rem;
    }
  }
  .cartAddressCard {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #ff0852;
    background-color: transparent;
    border-radius: 50%;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .addressAddCard {
    height: 25rem;
    h4 {
      font-size: 1.6rem;
      top: 3rem;
    }
    svg {
      width: 5rem;
      height: 5rem;
    }
  }
  .addressCard {
    height: 25rem;
    input {
      height: 3.6rem;
    }
    .addressCardInner {
      .title {
        font-size: 1rem !important;
        p {
          font-size: 1.2rem !important;
        }
      }
      .addressCardİnnerDiv {
        margin-top: 0 !important;

        .title {
          margin-bottom: 0 !important;
        }
      }
    }
    // .addressCardInnerBottom {
    // //   p {
    // //   }
    // }
  }
  .popup {
    .popupContainer {
      width: 45vw;
      max-height: 50rem;
      border-radius: 1.5rem;

      .addressPopup {
        padding: 2.4rem;
        position: relative;

        .close {
          position: absolute;
          top: -4rem;
          right: -4rem;
          border: none;
          background-color: transparent;

          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }

        .input-group {
          .input-child {
            width: 100%;
            margin-left: 0 !important;

            label {
              margin-bottom: 1rem;
              display: block;
              font-style: normal;
              font-weight: 400;
              font-size: 1.3rem;
              line-height: 1.5rem;

              color: #121212;
              opacity: 0.7;
            }

            input {
              width: -webkit-fill-available !important;
              height: 4.8rem;
              border: 0.1rem solid #dfdede;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
  .deliveryCart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199.9px) {
    .popup {
      .popupContainer {
        width: 60vw !important;
      }
    }
  }
  @media only screen and (max-width: 767.9px) {
    padding: 1.2rem 0 6.4rem;
    .popup {
      .popupContainer {
        width: 90vw !important;
      }
    }
    .cart-container {
      padding: 0;

      .cart-header {
        margin-bottom: 1.6rem;

        h5.title {
          font-size: 2.4rem;
          line-height: 2.8rem;
          margin-bottom: 0.4rem;
        }

        span {
          font-size: 1.4rem;
          line-height: 155%;
        }
      }

      .cartTable {
        padding: 0 1.2rem;
        padding-bottom: 0;
        margin-bottom: 0;

        .react-tabs__tab-list {
          overflow-x: auto;
          overflow-y: hidden;
          margin-bottom: 0;

          // &::-webkit-scrollbar {
          //     display: none;
          // }

          .react-tabs__tab {
            font-size: 1.6rem;
            line-height: 1.9rem;
            white-space: nowrap;
            padding: 1.2rem 3rem;

            &.react-tabs__tab--selected {
              padding: 1rem 0;
            }
          }
        }
      }

      .cartTableContent {
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
        // padding-top: 0;
        padding-bottom: 5.8rem;

        .newAccount {
          .newAccountContent {
            margin-bottom: 0;

            .newAccountTitle {
              display: flex;
              &.newaccountTitleRandom {
                flex-direction: column;
                align-items: flex-start;

                .newAccountTitleChild {
                  display: flex;
                  align-items: center;
                }

                p {
                  font-size: 1.3rem;
                  line-height: 1.5rem;
                  margin-top: 0.4rem;
                  padding-left: 3.6rem;
                }
              }
            }
          }
        }
      }

      .saveInfoChild {
        width: -webkit-fill-available !important;
      }

      .react-tabs__tab-panel {
        width: 100% !important;
      }

      .cartRight {
        width: 100% !important;
        padding: 0 !important;
        // margin-top: 4rem;

        .cartRightParent {
          max-height: 47.2rem !important;
          border-bottom: none !important;
          margin-bottom: 7.2rem !important;
        }

        .CartCard {
          padding: 1rem 0;
          border-bottom: none;

          .percent {
            left: 0.8rem;
            bottom: 2.4rem;
          }

          .imgContainer {
            min-width: 12rem;
            height: 14rem;
          }

          .orderHistoryDetail {
            .orderHistoryDetailButton {
              .right {
                align-items: center;

                p {
                  font-size: 2.4rem;
                  line-height: 135.9%;
                  align-items: center;
                }
              }
            }
          }

          .ratingCardSize {
            display: flex;
            align-items: center;
            margin-top: 0.4rem;

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 1.4rem;
              line-height: 135.9%;
              margin-left: 1.8rem;
            }
          }

          .deleteCart {
            right: 0;
          }
        }
      }

      .cartTotal {
        margin-top: 3.4rem;
        border-top: none !important;
        padding: 0 1.8rem !important;
        justify-content: space-between !important;

        .cartTotalChild,
        .CarttotalPrice {
          width: 45% !important;
        }

        .cartTotalChild {
          p {
            span {
              font-size: 2rem;
              line-height: 2.3rem;

              svg {
                display: none;
              }
            }
          }
        }
      }

      .orderComplated {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
          margin-bottom: 6rem;
        }
      }

      .greenButton {
        width: 100%;
        height: 5.2rem;
        font-size: 1.7rem;
        line-height: 2rem;
        margin-top: 4.2rem;
      }

      .authContentBody {
        margin-bottom: 5.2rem;

        .authContentFooterChild-second {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          a {
            width: 100%;
            margin-bottom: 2.4rem;
          }
        }
      }
    }
    .cartEptyContent {
      svg {
        width: 16rem;
        height: 10rem;
      }
    }
  }
}

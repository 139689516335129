.headerBottom {
  border-bottom: 0.08rem solid rgba(18, 18, 18, 0.15);

  // &.is-stickyy {
  //   position: fixed;
  //   top: 15.4rem;
  //   left: 0;
  //   width: 100%;
  //   z-index: 9;
  //   background-color: #ffffff !important;
  //   animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

  //   .headerBottomParent {
  //     background-color: #fff;
  //   }
  // }

  .headerBottomParent {
    background-color: #fdfcfc;
    position: relative;
    width: 100%;
  }

  .headerBottomChild {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 2.4rem;
    // margin-bottom: 0.6rem;

    a {
      font-family: "Playfair Display", serif !important;
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.1rem;
      letter-spacing: 0.1rem;

      text-transform: uppercase;
      color: #121212;
      margin-left: 3rem;
      display: flex;
      align-items: center;
      padding-bottom: 0.6rem;
      border-bottom: 0.4rem solid transparent;
      transition: 0.1s;
      svg {
        width: 2.4rem;
        height: 2.4rem;
        margin-left: 0.3rem;
        transition: 0.3s;
      }

      &:hover {
        border-bottom: 0.4rem solid #121212;

        svg {
          transform: rotate(-180deg);
        }
      }
    }

    .headerBottomMenuUl li a,
    .headerBottomMenuChild .title {
      font-family: "Montserrat", sans-serif !important;
      letter-spacing: 0;
    }
  }

  .headerBottomMenuParent {
    .headerBottomMenu {
      //   display: none;
      opacity: 0;
      visibility: hidden;
      // top: -100%;
      height: 0;
      width: 100%;
      z-index: -10;
      left: 0;
      transition: 0.2s all ease;
      position: absolute;
      width: -webkit-fill-available;
      padding: 5.8rem 0 10rem;
      box-shadow: 0 2rem 2rem rgba(125, 125, 125, 0.1);
      border-radius: 1rem;
      .container {
        display: flex;
        flex-wrap: wrap;
      }

      .headerBottomMenuChild {
        min-width: 10%;
        margin-right: 4.4rem;
        margin-bottom: 2rem;
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 2.3rem;

          color: #121212;
          margin-bottom: 2.4rem;
          margin-left: 0;
          padding-bottom: 0 !important;
          border: none !important;

          &:hover {
            color: #ff0852;
          }
        }
        .campaignsTitle {
          margin-bottom: 1.4rem;
        }

        .headerBottomMenuUl {
          display: flex;
          flex-direction: column;
          padding-left: 0;

          li {
            a {
              font-style: normal;
              font-weight: 500;
              font-size: 1.6rem;
              line-height: 1.9rem;

              color: #121212;
              margin-left: 0;
              opacity: 0.75;
              padding-bottom: 1.2rem !important;
              transition: 0.3s;
              border: none !important;
              text-transform: capitalize;
              &:hover {
                color: #ff0852;
              }
            }
          }
        }
      }
    }

    &:hover {
      .headerBottomMenu {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        background-color: #fdfcfc;
        display: block;
        height: auto;
        z-index: 4;
        transition: 0.1s all ease;
        opacity: 1;
        visibility: visible;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        &:hover {
          display: block;
        }
      }
      // &:last-child {
      //   .headerBottomMenu {
      //     right: 0 !important;
      //     left: auto !important;
      //   }
      // }
      a {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .brandListMenu {
    // display: flex;
    // flex-wrap: wrap;
    // width: 100%;
    margin-right: 0;
    .brandsAll {
      display: none;
      margin-bottom: 2.4rem;
    }
    .xl-2 {
      &:first-child {
        .brandsAll {
          display: block;
        }
      }
    }
    a {
      margin-left: 0;
    }
    ul {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 15;
      -webkit-box-orient: vertical;
      margin-bottom: 0.6rem;
      a {
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.5rem;

        color: #121212;
        display: block;
        white-space: nowrap;
        border: none !important;
        margin-left: 1rem;
        transition: 0.3s;
        &:hover {
          color: #ff0852;
        }
      }
    }
    span {
      display: block;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.5rem;

      color: #ff0852;
      margin-bottom: 1rem;
      margin-left: 1rem;
    }
    .linkMore {
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.5rem;

      color: #ff0852;
      margin-left: 0;
      margin-bottom: 3.2rem;
      text-transform: capitalize;
      margin-left: 1rem;
      border-bottom: none !important;
    }
  }
  .stickyHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  .oterMenuSection {
    position: relative;
    width: fit-content;
    &:last-child {
      .headerBottomMenu {
        position: absolute;
        right: 0;
        left: auto !important;
      }
    }
    .headerBottomMenuChild {
      margin: 0 !important;

      .headerBottomMenuUl {
        li {
          a {
            white-space: nowrap;
            color: #ff0852;
          }

          &:last-child {
            a {
              padding-bottom: 0 !important;
            }
          }
        }
      }
    }

    &:hover {
      .headerBottomMenu {
        position: absolute;
        left: 0;
        top: 100%;
        width: fit-content;
        background-color: #fdfcfc;
        display: block;
        height: auto;
        padding: 4rem;
        z-index: 4;
        min-width: -webkit-fill-available;
      }
    }

    &:last-child {
      &:hover {
        .headerBottomMenu {
          position: absolute;
          right: 0;
          left: auto !important;
        }
      }
    }
  }

  @media only screen and (max-width: 767.9px) {
    display: none;
  }
}

.popupCart {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(239, 239, 239, 0.374);
  box-shadow: inset 7.6rem -7.6rem 7.6rem rgba(186, 186, 186, 0.174),
    inset -7.6rem 7.6rem 7.6rem rgba(255, 255, 255, 0.174);
  backdrop-filter: blur(2.8rem);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .popupCartInner {
    width: 45vw;
    position: relative;
    max-height: 90vh;
    background: #ffffff;
    box-shadow: 0 4.4rem 4.4rem rgba(113, 102, 102, 0.15);
    border-radius: 0.4rem;
    padding: 2.4rem;

    .productCardPopup {
      border: 0.1rem solid rgba(113, 102, 102, 0.15);
      padding: 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .productCardPopupChild {
        display: flex;
        align-items: flex-start;
        width: 100%;
      }

      .img-container {
        width: 9rem;
        height: 9rem;
        min-width: 9rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .productPopupDetail {
        margin-left: 1.6rem;
        width: 100%;
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 155%;
          color: #121212;
          opacity: 0.9;
          margin-bottom: 2rem;
        }

        label {
          width: 50%;
          margin-bottom: 1.2rem;
          width: 9.4rem;
          height: 6.4rem;
          position: relative;
          margin-right: 0.8rem !important;

          span {
            font-size: 1.2rem;
            line-height: 100%;
          }

          .price {
            font-size: 1.4rem;
            line-height: 100%;

            span {
              font-size: 1.2rem;
              line-height: 100%;
              margin-left: 0.3rem;
            }
          }

          svg {
            width: 0.8rem;
            height: 0.6rem;
          }
        }
      }

      .quantity {
        .productDetailQuantity {
          height: 4rem;

          input {
            font-size: 1.4rem;
          }
        }
      }

      .productDetailQuantityPopup {
        margin-top: 1.6rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        .addToCard {
          height: 4rem;
          font-size: 1.4rem;
          margin-left: 2.4rem;
        }

        a,
        button {
          white-space: nowrap;
        }
      }
    }

    .closeButton {
      position: absolute;
      right: -3.2rem;
      top: -3.2rem;
      background-color: transparent;
      border: none;
      padding: 0;

      svg {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }

  &.popupCart-active {
    display: block;
  }

  @media only screen and (max-width: 991.9px) {
    .popupCartInner {
      width: 80vw;
    }
  }

  @media only screen and (max-width: 767.9px) {
    .addToCard {
      margin-top: 1.2rem;
    }
    .popupCartInner {
      width: 90vw;
      padding: 0;

      .closeButton {
        top: -3.6rem;
        right: 0;
      }
      .productCardPopupChild {
        display: flex;
        align-items: center !important;
        flex-direction: column;
        justify-content: center !important;
        width: 100%;
      }

      .productPopupDetail {
        width: -webkit-fill-available;
        margin-left: 0;
        h2 {
          text-align: center;
        }
      }

      .productCardPopup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .img-container {
          margin-bottom: 1.6rem;
        }

        .productDetailSize {
          label {
            width: 45%;

            &:nth-child(1),
            &:nth-child(3) {
              margin-right: 1.6rem;
            }
          }
        }
      }
      .backToShop {
        margin: 0 !important;
      }

      .productDetailQuantityPopup {
        display: flex;
        flex-direction: column;
        width: 100%;
        a,
        button {
          width: 100%;
          padding: 0;
          margin-bottom: 1.6rem;
          margin: 0 !important;
        }

        .quantity {
          margin-bottom: 1.6rem;
          width: 100%;
        }

        .productDetailQuantity {
          width: 100%;

          button {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.products {
  padding: 1.2rem 0;
  background-color: #fafafa;

  .productsHeader {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    margin-top: 4.8rem;

    .productsHeaderTitle {
      font-style: normal;
      font-weight: 800;
      font-size: 2.4rem;
      line-height: 2.8rem;
      
      
      color: #000000;
      display: flex;
      align-items: flex-end;
      margin-bottom: 2.4rem;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 1.8rem;
        
        color: #12121275;
        display: block;
        margin-left: 0.8rem;
      }
    }

    .productsTopFilter {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 1rem;

      .productsTopFilterChild {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        span {
          height: 2.2rem;
          font-style: normal;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.4rem;
         
          color: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 1rem 0 0.8rem;
          cursor: pointer;
          margin-bottom: 1rem;
          margin-right: 0.8rem;
          background: #f3f3f3;
          border-radius: 2rem;
          transition: 0.3s;

          svg {
            margin-left: 0.8rem;
            width: 1.2rem;
            height: 1.2rem;
          }

          &:hover {
            background: #f7e5ea;
          }
        }
      }
    }
  }

  .productCard {
    margin-bottom: 4rem;
  }

  .productsFilter {
    padding-right: 1rem;
  }

  .showProduct {
    display: none;
  }

  .productFilterSection {
    border-top: 0.1rem solid #eeeeee;
    padding-left: 1.6rem;

    .productFilterSectionChild {
      padding: 2.8rem 0;
      border-top: 0.1rem solid #eeeeee;

      &:first-child {
        border-top: none;
      }

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.6rem;
        
        
        color: #000000;
        margin-bottom: 2rem;

        svg {
          display: none;
        }
      }
    }
  }

  .filterInputsection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .filterInputsSectionChild {
      display: flex;
      align-items: center;
    }

    input {
      border: 0.1rem solid #cecdcd;
      box-sizing: border-box;
      border-radius: 1.5rem;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-align: center;
      
      color: #12121260;
      height: 3.8rem;
      width: 9rem;
      opacity: 1;

      &::placeholder {
        color: #12121260;
      }
    }

    span {
      font-weight: 600;
      font-size: 1.2rem;
      color: #121212;
      opacity: 0.6;
      display: block;
      margin: 0 0.8rem;
    }

    button {
      border: 0.1rem solid #f0f0f0;
      box-sizing: border-box;
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: transparent;
      width: 4.4rem;
      height: 3.8rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        opacity: 0.4;
      }
    }
  }

  .filterSearch {
    display: flex;
    align-items: center;
    position: relative;
    border: 0.1rem solid #cecdcd;
    box-sizing: border-box;
    border-radius: 1.5rem;
    height: 3.8rem;
    overflow: hidden;
    margin-bottom: 1.8rem;

    input {
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      
      color: #121212;
      background-color: transparent;
      border: none;
      height: 100%;
      padding: 0 4rem 0 2.2rem;
      width: -webkit-fill-available;

      &::placeholder {
        color: #12121260;
      }
    }

    button {
      position: absolute;
      right: 0;
      opacity: 0.45;
      height: 100%;
      padding: 0;
      width: 4rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: transparent;
      border: none;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  .filter-content {
    max-height: 18rem;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0.6rem;
    }

    &::-webkit-scrollbar-track {
      box-shadow: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #ff0852;
      border-radius: 1rem;
    }
  }

  .filter__control {
    border: 0.1rem solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    box-shadow: none !important;
    background-color: transparent;
    .css-qc6sy-singleValue div {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      
      
      color: #000000;

      svg {
        margin-right: 0.8rem;

        g {
          opacity: 1;
        }

        path {
          fill: #000000;
          transition: 0.3s;
        }
      }

      #Star {
        path {
          stroke: #000000 !important;
        }
      }
    }

    &:hover {
      border: 0.1rem solid #c4c4c4 !important;
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .react-select__menu {
    background: #ffffff;
    box-shadow: 0 0.8rem 2rem rgba(196, 196, 196, 0.2);
    border-radius: 2rem !important;
    overflow: hidden;
  }

  .filterInner {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: #000000;
    transition: 0.3s;
    height: 100%;
    cursor: pointer;

    svg {
      margin-right: 0.8rem;

      path {
        fill: #00000040;
        transition: 0.3s;
      }
    }

    &:hover {
      color: #ff0852;

      svg {
        g {
          opacity: 1;
        }

        path {
          opacity: 1;
          fill: #ff0852;
        }
      }
    }

    &.filterInnerP {
      svg {
        path {
          fill: #fff !important;
          stroke: #00000040;
        }
      }

      &:hover {
        svg {
          path {
            stroke: #ff0852;
          }
        }
      }
    }
  }

  .productPageAbout {
    background: #f7f7f8;
    padding: 8rem 0;

    .container {
      display: flex;
      justify-content: center;
    }

    .productPageAboutContent {
      width: 55%;

      h2,
      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 2.8rem;
        
        color: #121212;
        margin-bottom: 1.8rem;
      }
      h3 {
        font-size: 2rem;
      }

      .subTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 150%;
        
        color: #121212;
        margin-bottom: 0.8rem;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 150%;
        
        color: #121212;
        margin-bottom: 0.7rem;

        span {
          font-weight: 600;
        }
      }
    }
  }

  .filterMobileHeader {
    display: none;
  }

  @media only screen and (max-width: 767.9px) {
    .css-b62m3t-container {
      width: 50%;
    }
    .productsTopFilterChild {
      display: none !important;
    }

    .filterInputsSectionChild {
      width: 100%;

      input {
        width: 100%;
      }
    }

    .productsHeaderTitle {
      display: flex;
      flex-direction: column;
      align-items: center !important;
      justify-content: center;
    }

    .productsHeader {
      margin-top: 1.8rem;

      .productsTopFilter {
        margin-bottom: 2.4rem;
      }
    }

    .filter__indicators {
      display: none;
    }

    .css-qc6sy-singleValue {
      display: flex;
      justify-content: flex-start;
    }

    .filterButton {
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      
      
      color: #000000;
      border: 0.1rem solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 3rem;
      height: 3.7rem;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.8rem;
      background-color: transparent;

      svg {
        margin-right: 0.8rem;
      }
    }

    .productCard {
      margin-bottom: 3.2rem;
      .hoverEffectSpan {
        font-size: 0.8rem;
        line-height: 1.1rem;
        
        color: #000000;
        border: 0.1rem solid #121212;
        position: absolute;
        padding: 0.6rem 1rem;
        margin: 0 1rem;
        text-transform: capitalize;
      }
      .productCardHeader {
        height: 21.2rem;

        a {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            object-fit: contain;
            width: 70%;
          }
        }

        .productCardBadge {
          height: 2.2rem;
          font-size: 1.1rem;
          line-height: 1.3rem;
          padding: 0 1rem;
          left: 1.2rem;
          top: 1.2rem;
        }

        .interest {
          height: 2.2rem;
          font-size: 1.3rem;
          line-height: 135.9%;
          padding: 0 1rem;
          left: 1.2rem;
          bottom: 1.2rem;
        }

        .heart {
          right: 1.2rem;
          top: 1.2rem;
        }

        .productCardAddToCart {
          display: none;
        }
      }

      .productCardBody {
        .title {
          font-size: 1.3rem;
          line-height: 155%;
        }

        .productCardBodyHeader {
          margin-bottom: 0.6rem;

          .price {
            span {
              font-size: 1.8rem;
              line-height: 135.9%;
            }

            .discount {
              font-size: 1.4rem;
              line-height: 135.9%;
            }
          }
        }
      }

      .mobileAddToCart {
        display: flex;
      }
      &:hover {
        .hoverEffectSpan {
          bottom: 1rem;
        }
      }
    }

    .productPageAbout {
      padding: 3.6rem 0;

      .productPageAboutContent {
        width: 100%;
      }
    }

    .productsFilter {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 102vh;
      background-color: #fdfcfc;
      z-index: 10;
      overflow: auto;
      padding-right: 0;
      display: none;

      &.active {
        display: block;
      }

      .productFilterSection {
        border: none;
        padding: 0 2.4rem 7.2rem;
      }

      .productFilterSectionChild {
        padding-bottom: 0;
        border-top: none !important;

        .title {
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 1.9rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0;
          cursor: pointer;

          svg {
            transform: rotate(-90deg);
            display: block;
            transition: 0.3s;
          }

          &.active {
            svg {
              transform: rotate(0);
            }
          }
        }
      }

      .filter-content,
      .filterContentParent {
        height: 0;
        -moz-transition: height 1s ease;
        -webkit-transition: height 1s ease;
        -o-transition: height 1s ease;
        transition: height 1s ease;
        overflow-y: auto;

        &.active {
          height: auto;
          margin-top: 1.6rem;
        }
      }

      .filter-content {
        max-height: 24rem;
      }

      .showProduct {
        position: fixed;
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.1rem;
        text-align: center;
        
        
        color: #fdfcfc;
        background: #2fb12f;
        border-radius: 3rem;
        width: 94%;
        height: 5.4rem;
        z-index: 9999999;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 1rem;
        border: none;
      }

      .filterMobileHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.4rem 2.4rem 0.9rem 1.6rem;

        .close {
          border: none;
          background-color: transparent;
          padding: 0;
          svg {
            width: 4.1rem;
            height: 4.1rem;
          }
        }

        .filterName {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 2.8rem;
          
          
          color: #000000;

          svg {
            width: 2.8rem;
            height: 2.8rem;
            margin-right: 0.8rem;

            path {
              fill: #ff0852;
            }
          }
        }

        .clear {
          font-style: normal;
          font-weight: normal;
          font-size: 1.3rem;
          line-height: 1.5rem;
          
          color: #121212;
          opacity: 0.7;
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}

.orderHistoryCard {
  padding: 0 0.3rem;
  border-bottom: 0.1rem solid #ebeaea;
  position: relative;
  display: flex;
  align-items: flex-start;

  .imgContainer {
    background: #f3f3f3;
    border-radius: 1.5rem;
    width: 12rem;
    height: 13rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-height: 7rem;
      object-fit: contain;
    }
  }
  .orderDelivery {
    display: flex;
    justify-content: flex-end;
  }
  .percent {
    position: absolute;
    bottom: 3.6rem;
    left: 0.8rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 135.9%;
    text-align: right;

    color: #fdfcfc;
    background: #ff0852;
    height: 1.8rem;
    padding: 0 0.4rem;
    border-radius: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:last-child {
    border-bottom: none;
  }

  .orderHistoryDetail {
    margin-left: 1.6rem;
    padding: 1rem 0;
    width: 100%;
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 145%;

      color: #121212;
      margin-bottom: 0.2rem;
      width: 80%;
      height: 5.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .rating-card {
      display: flex;
      align-items: center;
    }

    .orderHistoryDetailButton {
      padding: 2.2rem 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .right {
        font-style: normal;
        line-height: 135.9%;

        display: flex;
        align-items: flex-start;

        p {
          color: #ff0852;
          font-weight: 600;
          font-size: 2.8rem;
          display: flex;
          align-items: flex-end;
          line-height: 95%;
          svg {
            margin-left: 0.6rem;
            width: 2rem;
            height: 1.8rem;
            path {
              fill: #ff0852;
            }
          }
        }

        span {
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.9rem;
          text-align: right;

          color: #121212;
          margin-left: 1.2rem;
          position: relative;

          svg {
            margin-left: 0.5rem;
            width: 1rem;
            height: 0.8rem;
          }

          &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            height: 0.2rem;
            background-color: #ff0852;
            transform: rotate(10deg);
          }
        }
      }
    }

    .left {
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 135.9%;

      color: #121212;
      display: flex;
      align-items: center;

      span {
        padding-left: 1.3rem;
        margin-left: 1.3rem;
        border-left: 0.1rem solid #12121210;
      }
    }
  }

  @media only screen and (max-width: 767.9px) {
    .imgContainer {
      min-width: 12rem;
      height: 16rem;

      img {
        max-height: 9.7rem;
      }
    }

    .orderHistoryDetail {
      h6 {
        font-size: 1.4rem;
        line-height: 145%;
        margin-bottom: 0.6rem;
      }
    }

    .rating-line {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      //   .left {
      //     margin-left: 1rem;
      //     padding-left: 1rem;
      //     border-left: 0.08rem solid rgba(18, 18, 18, 0.1);
      //   }

      .rating-card {
        svg {
          width: 1.1rem;
          height: 1.1rem;
        }
      }
    }
  }
}
.orderHistoryTotal {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 0.1rem solid #f5f5f5;
  border-radius: 1.5rem;
}

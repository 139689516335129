.supportSidebar {
    // height: 100%;
    padding: 1.6rem 0;

    a {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
        
        
        color: #121212;
        opacity: 0.7;
        margin-bottom: 2.8rem;

        &.red {
            font-weight: 800;
            font-size: 1.8rem;
            line-height: 2.1rem;
            
            opacity: 1;
        }
    }

}
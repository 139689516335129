.address {
  padding: 1.2rem 0 8.8rem;

  .popup {
    .popupContainer {
      width: 30vw;
      max-height: 50rem;
      border-radius: 1.5rem;

      .addressPopup {
        padding: 2.4rem;
        position: relative;

        .close {
          position: absolute;
          top: -4rem;
          right: -4rem;
          border: none;
          background-color: transparent;

          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }

        .input-group {
          .input-child {
            width: 100%;
            margin-left: 0 !important;

            label {
              margin-bottom: 1rem;
              display: block;
              font-style: normal;
              font-weight: 400;
              font-size: 1.3rem;
              line-height: 1.5rem;
              
              color: #121212;
              opacity: 0.7;
            }

            input {
              width: -webkit-fill-available !important;
              height: 4.8rem;
              border: 0.1rem solid #dfdede;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
  @media (max-width: 767.9px) {
    .popup {
      .popupContainer {
        width: 90vw;
        max-height: 50rem;
        border-radius: 1.5rem;
        .close {
          position: absolute;
          right: 0 !important;
        }
        input {
          height: 4rem;
        }
      }
    }
  }

  &-edit-btn {
    font-size: 14px;
    color: rgba(#22282F, 0.45);
    margin-right: 6px;
  }
}

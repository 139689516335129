.productDetail {
  padding: 1.2rem 0 4.8rem;
  iframe {
    width: 100%;
    height: 50rem;
    position: static !important;
  }
  .productDetailHeader {
    padding: 6.2rem 0 2.6rem;
    display: flex;
    flex-direction: column;

    .title {
      font-style: normal;
      font-weight: 800;
      font-size: 3.2rem;
      line-height: 3.8rem;

      color: #121212;
      margin-bottom: 0.8rem;
    }

    .productCardRating {
      display: flex;
      align-items: center;
      margin-right: 1.2rem;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;

        color: #8b8b8b;
        display: block;
      }

      svg {
        width: 2rem;
        height: 2.2rem;
      }

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;

        color: #121212;
        opacity: 0.55;
        display: block;
        padding-left: 2.6rem;
      }
    }
  }
  .quantity-picker {
    border: 0.1rem solid #121212;
    box-sizing: border-box;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5.6rem;
    width: 32%;
    padding: 0 2rem;
    background-color: #fff;
    .quantity-modifier {
      background-color: transparent;
      width: 1.2rem;
      height: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #121212;
    }
  }
  .productDetailSlider {
    background: #fff;
    width: 100%;
    overflow: hidden;
    border-radius: 1.5rem;
    height: 55.6rem;
    position: relative;
    .swiper {
      border: 0.05rem solid #e9e9e9;
      border-radius: 1.5rem;
      overflow: hidden;
      height: -webkit-fill-available;

      .swiper-slide {
        background: #fff;
        border-radius: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 55.6rem;
        width: 100%;
        overflow: hidden;

        img {
          width: 90%;
          height: 90%;
          object-fit: contain;
        }
      }
    }
    .hoverEffectSpan {
      // display: none;
      transition: 0.4s;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.4rem;

      color: #000000;
      border: 0.1rem solid #121212;
      position: absolute;
      padding: 0.6rem 1rem;
      width: -webkit-fill-available;
      margin: 0 2rem;
      white-space: break-spaces;
      text-align: center;
      background: #fff;
      // opacity: 0;
      bottom: -8rem;
      text-transform: capitalize;
    }

    &:hover {
      .hoverEffectSpan {
        display: block;
        bottom: 2rem;
        opacity: 0.8;
        z-index: 3;
      }
    }
  }

  .productDetailRight {
    padding: 2.4rem 0;

    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.1rem;

      color: #000000;
      opacity: 0.8;
      margin-bottom: 2rem;
    }

    .productDetailButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.2rem;
    }
    .productDetailSize {
      display: block !important;
      overflow: hidden;
      .swiper {
        .swiper-wrapper {
          transition: none !important;
          transform: none !important;
        }
        .swiper-slide {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
  .swiperThumbsImg {
    display: none;
  }

  .productDetailHeart {
    border: 0.1rem solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 8.2rem;
    height: 5.6rem;
    // margin-left: 1.6rem;

    .heart {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
      }

      svg {
        width: 2.8rem;
        height: 2.8rem;
      }
    }
  }

  .newsContentBottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a,
    span,
    button {
      background: #f3f3f3;
      width: 4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.8rem;
      border-radius: 50%;

      svg {
        width: 1.9rem;
        height: 1.9rem;
      }
    }
  }

  .productDetailSocials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.4rem;

    .oneClickOrder {
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000000;
      border-bottom: 0.1rem solid #121212;
      padding-bottom: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        margin-right: 0.8rem;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .react-tabs {
    margin-bottom: 5.6rem;
  }

  .productDetailTab {
    width: 100%;
    margin-top: 6.4rem;

    .react-tabs__tab-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border: none;
      margin-bottom: 6.2rem;

      .react-tabs__tab {
        font-style: normal;
        font-weight: 600;
        font-size: 2.8rem;
        line-height: 3.3rem;
        text-align: center;

        color: #12121235;
        border-bottom: 0.1rem solid #12121230;
        width: 100%;
        padding-bottom: 1.6rem;
      }

      .react-tabs__tab--selected {
        border: none;
        border-bottom: 0.4rem solid #121212;
        color: #121212;
        background-color: transparent;
      }
    }
  }

  .productDetailIndicators {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 2.4rem;

    .productDetailIndicatorsChild {
      width: fit-content;
      margin-right: 9.2rem;

      &:last-child {
        margin-right: 0;
      }

      p {
        margin-bottom: 2.6rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        strong {
          font-style: normal;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 2.1rem;

          color: #000000;
          white-space: nowrap;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          padding-left: 1rem;
        }
        span,
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.9rem;
          margin-left: 0.8rem;
          color: #00000075;
          display: block;
          text-transform: capitalize;
          white-space: nowrap;
          border-bottom: 0.1rem solid #121212;
          &:hover {
            color: #ff0852;
            border-bottom: 0.1rem solid #ff0852;
          }
        }
        span {
          cursor: text;
          &:hover {
            color: #00000075;
            border-bottom: 0.1rem solid #121212;
          }
        }
      }
    }
  }
  .addToCard {
    padding: 0 2.2rem;
    width: -webkit-fill-available;
    margin: 0 2.4rem;
  }
  .productDetailInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 155%;

    color: #121212;
    display: inline;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 2rem;
      line-height: 155%;

      color: #121212;
      display: inline;
      width: 70%;

      a {
        display: inline;
        font-weight: 600;
        color: #121212;
        font-size: 2rem;
        line-height: 155%;
      }
    }
  }

  .subtitle {
    font-style: normal;
    font-weight: 800;
    font-size: 2.8rem;
    line-height: 3.3rem;
    text-align: center;

    color: #121212;
    margin-bottom: 2.8rem;
  }

  .similarProducts {
    margin-bottom: 8.2rem;

    .paginationSwiper {
      .swiper-pagination-horizontal {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 1199.9px) {
    .productDetailRight {
      .productDetailSize {
        width: 90%;
      }
    }
  }

  @media only screen and (max-width: 767.9px) {
    background-color: #fdfcfc;
    .quantity-picker {
      width: 38%;
      padding: 0;
      justify-content: space-around;

      .quantity-display {
        width: 3rem !important;
        padding: 0;
      }
    }
    .productDetailRight {
      .productDetailSize {
        width: 100%;
        .swiper {
          margin-bottom: 0;
        }
        .swiper-slide {
          height: 13.2rem;
          width: 46% !important;
        }
        label {
          width: 100%;
          margin-bottom: 0.8rem;

          .sizeCard {
            height: 13.2rem;
            .price {
              align-items: center;
            }
            img{
              margin-bottom: 0;
            }
          }
        }
      }

      .productDetailButtons {
        position: fixed;
        bottom: 8rem;
        z-index: 5;
        // background: #fdfcfc;
        left: 0;
        width: -webkit-fill-available;
        padding: 0.9rem 1.6rem 0.9rem 2rem;

        .productDetailQuantity {
          width: 30%;
        }

        .addToCard {
          width: 60%;
          font-size: 1.2rem;
          line-height: 1.6rem;
          margin: 0 !important;
          padding: 0 0.6rem;
        }
      }
    }
    .productDetailRight {
      padding: 1.2rem 0;
    }
    .productDetailSocials {
      margin-top: 0;
    }
    .productDetailSlider {
      order: -1;
      position: relative;
    }

    .productDetailHeader {
      padding: 1.2rem 0;

      .title {
        font-size: 2.4rem;
        line-height: 155%;
      }

      .productCardRating {
        span {
          margin-left: 0;
        }

        p {
          font-size: 1.4rem;
          line-height: 1.6rem;
          border-left: 0.1rem solid #e5e5e5;
          padding-left: 1.6rem;
          margin-left: 1.4rem;
        }
      }
    }

    .productDetailSlider {
      height: auto;
      .swiper {
        .swiper-slide {
          height: auto;
          position: relative;
          padding-top: 100%;
          // height: 34.2rem;

          .lazy-load-image-background {
            position: absolute;
            top: 1rem;
          }
        }
      }
    }

    .similarProducts {
      .swiper-slide {
        background-color: transparent;
      }

      .paginationSwiper {
        padding-bottom: 2.4rem !important;

        .swiper-pagination-horizontal {
          display: block;
          // bottom: 4rem !important;
        }
      }
    }

    .heart {
      z-index: 3;

      svg {
        width: 3.5rem;
        height: 3.5rem;
      }
    }

    .productDetailTab {
      .react-tabs__tab-list {
        display: flex;
        flex-direction: row;
        margin-bottom: 2.4rem;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        .react-tabs__tab {
          // border-bottom: none !important;
          font-size: 2.4rem;
          line-height: 2.8rem;
          background-color: transparent;
          outline: none;
          box-shadow: none;
          font-style: normal;
          font-weight: 800;
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
      }
    }

    .productDetailIndicators {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;

      .productDetailIndicatorsChild {
        margin: 0;

        p {
          // white-space: pre-wrap;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 2rem;

          span,
          a {
            white-space: nowrap;
            margin-right: 0.8rem;
            margin-left: 1rem;
            font-size: 1.6rem;
            line-height: 1.9rem;

            &:first-child {
              margin-left: 0.8rem;
            }
            &:hover {
              color: #ff0852;
            }
          }
        }
      }
    }

    .productDetailInfo {
      font-size: 1.4rem;
      line-height: 155%;
      p {
        width: 100%;
        font-size: 1.4rem;
        line-height: 155%;
      }
    }
  }
}

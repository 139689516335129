.accountSidebar {
    // border-right: .1rem solid #D6D6D6;
    // height: 100%;
    padding: 1.6rem 0;

    a {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
        
        
        color: #121212;
        opacity: 0.4;
        margin-bottom: 2.8rem;

        &.red {
            font-weight: 800;
            font-size: 1.8rem;
            line-height: 2.1rem;
            
            opacity: 1;
        }
    }

    @media only screen and (max-width: 767.9px) {
        display: flex;
        align-items: center;
        flex-direction: row;
        border-right: none;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        a {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.6rem;
            white-space: nowrap;
            margin-bottom: 0;
            border-bottom: .1rem solid #D6D6D6;
            padding: 0 2rem 1.2rem;

            &.red {
                font-weight: 800;
                font-size: 1.6rem;
                line-height: 1.9rem;
                padding: 0;
                border-bottom: .4rem solid #121212;
                padding-bottom: .9rem;
            }
        }
    }
}
.newsCard {
  margin-bottom: 3.2rem;

  .newsCardHeader {
    height: 34rem;
    width: 100%;
    border-radius: 3rem;
    overflow: hidden;

    img,
    span {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;
    }
  }

  .newsCardBody {
    padding: 2.4rem 1.2rem 1.6rem;

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 155%;
      
      color: #121212;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 6rem;
    }
  }

  &:hover {
    .newsCardHeader {
      overflow: hidden;

      img {
        transform: scale(1.1);
      }
    }
  }

  @media only screen and (max-width: 767.9px) {
    margin-bottom: 1.6rem;
    .newsCardHeader {
      height: 30rem;
    }

    .newsCardBody {
      p {
        font-size: 1.6rem;
        line-height: 155%;
        height: 5rem;
        -webkit-line-clamp: 2;
      }
    }
  }
}

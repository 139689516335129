.stores {
  padding: 1.2rem 0 4.8rem;

  h2.title {
    font-style: normal;
    font-weight: 800;
    font-size: 4rem;
    line-height: 2.8rem;
    color: #000000;
    margin: 4.5rem 0;
  }

  .storeLeft {
    border: 0.1rem solid #d6d6d6;
    box-sizing: border-box;
    border-radius: 1.5rem;
    margin-right: 4rem;

    .storeLeftCard {
      .storeTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 155%;
        
        color: #000000;
        padding: 2.4rem 3.2rem 1.4rem;
        border: none;
        background-color: transparent;
        // border-bottom: .1rem solid rgba(213, 213, 213, 0.6);
        display: flex;
        justify-content: flex-start;
        cursor: pointer;

        &::after {
          display: none;
        }

        svg {
          display: none;
        }
      }

      .storeLeftCardBody {
        padding: 0 3.2rem 1.6rem;
        display: none;
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 1.3rem;
          line-height: 1.5rem;
          
          color: #000000;
          display: flex;
          align-items: center;
          margin-bottom: 0.8rem;

          svg {
            width: 1.8rem;
            min-width: 1.8rem;
            height: 1.8rem;
            margin-right: 0.6rem;
            path {
              fill: #ff0852;
            }
          }
        }
      }
      a {
        border-bottom: 0.1rem solid rgba(213, 213, 213, 0.6);

        &.active {
          .storeTitle {
            color: #ff0852;
          }
          .storeLeftCardBody {
            display: block;
          }
        }
      }
    }
  }

  .storesMainVideo {
    position: relative;
    margin-bottom: 3.2rem;
    overflow: hidden;
    border-radius: 1.5rem;
  }

  .video-react-controls-enabled {
    padding-top: 0 !important;

    video {
      width: 100%;
      height: 41rem;
      object-fit: cover;
      border-radius: 1.5rem;
    }

    .video-react-button {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto !important;
      background-image: url(../../assets/play.svg);
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
      content: "";
      color: transparent;
      width: 100%;
      background-position: center;
    }

    .video-react-control-bar {
      display: none;
    }
  }

  // .storeLeftCardBody {

  //   transition: transform 0.5s ease;
  // }

  .map {
    iframe {
      border-radius: 1.5rem;
      border: none;
      width: 100%;
      height: 46.1rem;
    }
  }

  @media only screen and (max-width: 767.9px) {
    padding: 1.2rem 0 7.2rem;

    h2.title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-align: center;
      margin: 2rem 0 3.6rem;
    }

    .storeLeft {
      margin: 0;
      border: none;
      margin-bottom: 1.4rem;

      .storeLeftCard {
        .storeTitle {
          font-size: 1.8rem;
          line-height: 155%;
          padding: 0.6rem 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 0.1rem solid rgba(18, 18, 18, 0.1) !important;

          svg {
            display: block;
            width: 2.8rem;
            height: 2.8rem;
            transition: 0.3s all;
            transform: rotate(-90deg);
          }
        }

        a {
          border: none !important;

          &.active {
            background-color: transparent;
            border-bottom: transparent;
            color: #ff0852;
            outline: none;
            .storeLeftCardBody {
              height: auto;
              // padding: 0 3.2rem 1.6rem !important;

              svg {
                path {
                  fill: #ff0852;
                }
              }

              p {
                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }
              }
            }
            svg {
              transform: rotate(0deg);

              path {
                fill: #ff0852;
              }
            }
          }

          .storeLeftCardBody {
            padding: 2.4rem 0;
          }
          &:last-child {
            a {
              border: none !important;
            }
          }
        }
      }
    }

    .video-react-controls-enabled {
      video {
        height: 25rem;
      }
    }

    .maps {
      iframe {
        height: 53.2rem;
      }
    }
  }

  @media only screen and (max-width: 499.9px) {
    .video-react-controls-enabled {
      video {
        height: 18rem;
      }
    }
  }
}

.selectedForYou {
  padding: 4.8rem 0 5rem;

  .selectedForYouHeader {
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex-direction: column;
    margin-bottom: 4rem;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 3.5rem;
      line-height: 4.2rem;
      text-align: center;
      
      
      color: #121212;
      margin-bottom: 1.8rem;
    }

    .react-tabs__tab-list {
      border: 0.1rem solid #baa7a7;
      box-sizing: border-box;
      border-radius: 3rem;
      display: flex;
      align-items: center;
      height: 5.2rem;
    }

    .react-tabs__tab {
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 140%;
      text-align: center;
      
      
      color: #121212;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      border: 0;
      padding: 0 3.4rem;
      border-right: 0.02rem solid #e0dfdf;
      height: 2.2rem;
      margin-top: -0.2rem;

      &:last-child {
        border: 0.02rem solid transparent;
      }

      &.react-tabs__tab--selected {
        background: #121212;
        color: #fdfcfc;
        font-weight: 600;
        border-radius: 3rem;
        height: 5rem;
        border: 0.02rem solid transparent;
      }
    }
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0 !important;
  }

  .react-tabs__tab-panel {
    position: relative;
  }

  .swiper-pagination-bullet {
    border: 0.07rem solid #868686;
    box-sizing: border-box;
    border-radius: 3rem;
    width: 1.8rem;
    height: 0.4rem;
    background: transparent;
    transition: 0.3s;

    &:hover {
      background: #ff0852;
      border: 0.07rem solid #ff0852;
      opacity: 1;
    }

    &:focus {
      background-color: #700022;
      border: 0.07rem solid #700022;
    }
  }

  .swiper-pagination-bullet-active {
    background: #121212;
    width: 5.4rem;
    border: 0.07rem solid #121212;
    transition: 0.3s;

    &:hover {
      background: #121212;
      border: 0.07rem solid #121212;
    }
  }

  .swiper-wrapper {
    padding-bottom: 2rem;
  }

  @media only screen and (max-width: 767.9px) {
    padding: 0;
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
    .selectedForYouHeader {
      .title {
        font-size: 2rem;
        line-height: 2.8rem;
        margin-bottom: 1.4rem;
      }

      .react-tabs__tab-list {
        height: 4.4rem;
        width: 100%;

        .react-tabs__tab {
          padding: 0;
          width: 100%;
          font-size: 1.2rem;

          &.react-tabs__tab--selected {
            height: 4rem;
          }
        }
      }
    }
  }
}

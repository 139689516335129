.orderHistoryPage {
  padding: 1.2rem 0 8.8rem;
  .accountCard {
    display: flex;
    align-items: center;
    span {
      width: 20%;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #121212;
      position: relative;

      svg {
        right: auto;
        width: 1.6rem;
        height: 1.6rem;
        margin-left: 0.4rem;
      }
    }
  }
  .accountCardParent {
    margin-bottom: 2.4rem;
    span {
      font-weight: 600;
      font-size: 1.8rem;
    }
  }
  .orderHistoryRightCard {
    background: #ffffff;
    // border: 0.1rem solid #f5f5f5;
    box-sizing: border-box;
    border-radius: 1.5rem;

    .rightCardinner {
      padding: 3.4rem;

      p {
        font-family: "Montserrat", sans-serif !important;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.6rem;

        color: #12121275;
        display: flex;
        align-items: flex-end;
        margin-bottom: 1rem;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 1.9rem;
          text-align: right;

          color: #121212;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          margin-left: 3.6rem;

          svg {
            width: 1.2rem;
            height: 0.8rem;
          }
        }
      }

      .total {
        font-style: normal;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 2.8rem;

        color: #12121270;
        display: flex;
        align-items: center;
        padding-top: 1.5rem;
        border-top: 0.1rem solid #e7e7e7;
        margin-top: 2.4rem;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 2.8rem;
          text-align: right;

          color: #121212;
          display: flex;
          align-items: center;
          margin-left: 3rem;

          svg {
            width: 2rem;
            height: 1.5rem;
          }
        }
      }
    }
  }
  .MuiAccordion-gutters {
    &::before {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .MuiAccordionSummary-content {
    position: relative;
    svg {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .orderHistoryCardParent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid #575757;
    padding: 0;
    .orderHistoryCard {
      padding: 0 !important;
    }
  }
  .orderDelivery {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .orderDeliveryChild {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.08em;
      color: #121212;
      line-height: 2.1rem;
      margin-top: 2rem;
      opacity: 0.85;
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
      }
    }
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    display: none;
  }

  .account-order {
    padding: 3.2rem 2.6rem 0;

    .account-order-header {
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 2.3rem;

      color: #121212;
      padding: 0 0 1.6rem;
      width: 100%;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
    }

    .accountOrderTotal {
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;

      color: #12121270;
      padding-left: 2rem;
    }
  }

  .cartEmpty {
    background-color: #fdfcfc;
    width: 100%;
    border-radius: 1.5rem;

    .cartEptyContent {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding-top: 7.4rem;
      padding-bottom: 6.4rem;

      a {
        margin-bottom: 2.8rem;
        display: block;

        svg {
          width: 22.4rem;
          height: 15.4rem;
        }
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 2rem;
        line-height: 2.4rem;

        color: #12121296;
        margin-bottom: 3.2rem;
      }
    }

    .cartEmptyButton {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: fit-content;
      padding: 0 4.2rem;
    }
  }

  .MuiPaper-root {
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin-bottom: 3.2rem;
  }
  @media (max-width: 767.9px) {
    .accountCard {
      span {
        font-size: 1rem;
        line-height: 100%;
      }
    }
    .accountCardParent span {
      font-size: 1.2rem;
    }
    .account-order {
      padding: 0;
      padding-top: 3.2rem;

      .account-order-header {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
    .cartEptyContent {
      a {
        margin-bottom: 1.4rem !important;
        svg {
          width: 16rem !important;
          height: 10rem !important;
        }
      }
      p {
        font-size: 1.4rem !important;
      }
    }

    .orderHistoryRightCard {
      background: #ffffff;
      border: 0.08rem solid #f5f5f5;
      box-sizing: border-box;
      border-radius: 1.5rem;
      margin-top: 2.4rem;

      .rightCardinner {
        padding: 1.9rem 2.3rem 1.9rem 1.8rem;
        display: flex;

        div {
          width: 50%;

          p {
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .total {
          border-top: none;
          border-left: 0.08rem solid #e7e7e7;
          margin-top: 0;
          padding-top: 0;
          padding-left: 1.9rem;
          font-size: 2rem;
          line-height: 2.3rem;

          span {
            font-size: 2rem;
            line-height: 2.3rem;
            margin-left: 1.1rem;
          }
        }
      }
    }
  }
}

.news {
    padding: 1.2rem 0 7.2rem;

    .news-header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2.8rem;
        margin-top: 3.6rem;

        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 4.8rem;
            line-height: 5.6rem;
            
            
            color: #121212;
        }
    }

    @media only screen and (max-width:767.9px) {
        .news-header {
            margin-top: 0;
            margin-bottom: 1.8rem;

            .title {
                font-size: 2.4rem;
                line-height: 2.8rem;
            }
        }
    }
}
.CartCard {
  padding: 2.8rem 0.3rem;
  border-bottom: 0.1rem solid #ebeaea;
  position: relative;
  display: flex;
  align-items: flex-start;

  .imgContainer {
    background: #fff;
    border-radius: 1.5rem;
    width: 12rem;
    height: 13rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-height: 7rem;
      object-fit: contain;
    }
  }

  .percent {
    position: absolute;
    bottom: 3.6rem;
    left: 0.8rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 135.9%;
    text-align: right;
    
    color: #fdfcfc;
    background: #ff0852;
    height: 1.8rem;
    padding: 0 0.4rem;
    border-radius: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:last-child {
    border-bottom: none;
  }

  .orderHistoryDetail {
    margin-left: 1.6rem;
    padding: 1rem 0;

    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 145%;
      
      color: #121212;
      margin-bottom: 0.2rem;
      width: 80%;
      height: 5.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .rating-card {
      display: flex;
      align-items: center;
    }

    .orderHistoryDetailButton {
      padding: 1.8rem 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 135.9%;
        
        color: #121212;
        display: flex;
        align-items: center;

        span {
          padding-left: 1.3rem;
          margin-left: 1.3rem;
          border-left: 0.1rem solid #12121210;
        }
      }

      .right {
        font-style: normal;
        line-height: 135.9%;
        
        display: flex;
        align-items: flex-start;
        margin-left: 1.6rem;
        p {
          color: #121212;
          font-weight: 600;
          font-size: 2.8rem;
          display: flex;
          align-items: flex-end;

          svg {
            margin-left: 0.6rem;
            width: 1.9rem;
            height: 1.4rem;
          }
        }

        span {
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.9rem;
          text-align: right;
         
          color: #121212;
          margin-left: 1.2rem;
          position: relative;

          svg {
            margin-left: 0.5rem;
            width: 1rem;
            height: 0.8rem;
          }

          &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            height: 0.2rem;
            background-color: #ff0852;
            transform: rotate(10deg);
          }
        }
      }
    }
  }

  .cart-inputs {
    border: 0.1rem solid #121212;
    box-sizing: border-box;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 8rem;
    margin-right: 1.6rem;

    input,
    button {
      font-style: normal;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 1.5rem;
      
      
      color: #121212;
      background-color: transparent;
      border: none;
      text-align: center;
    }

    input {
      width: 2.8rem;
      padding: 0;
    }

    button {
      // font-size: 2.2rem;
      padding: 0;
    }
  }

  .quantity-picker {
    display: flex;
    align-items: center;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 8rem;
    background-color: transparent;
  }

  .deleteCart {
    position: absolute;
    right: 2.4rem;
    top: 2.8rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    
    color: #121212;
    opacity: 0.3;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    transition: 0.3s;

    svg {
      margin-right: 0.8rem;
    }
  }

  &:hover {
    .deleteCart {
      opacity: 1;
    }
  }

  &:first-child {
    padding-top: 0;

    .deleteCart {
      top: 0;
    }
  }
}

.personalInfo {
    padding: 1.2rem 0 8.4rem;

    .account-personal {
        padding: 3.2rem 1.6rem;
    }

    @media only screen and (max-width: 767.9px) {
        .account-personal {
            padding: 2.4rem 0;
        }
    }
}
.aboutSection {
  background: #f7f7f8;
  padding: 8rem 0;

  .container {
    display: flex;
    justify-content: center;
  }

  .aboutSectionChild {
    width: 55%;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.8rem;
      
      color: #121212;
      margin-bottom: 1.8rem;
    }

    .subTitle,
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 150%;
      
      color: #121212;
      margin-bottom: 0.8rem;
    }

    p,
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 150%;
      
      color: #121212;
      margin-bottom: 0.7rem;

      span,
      strong,
      b {
        font-weight: 600;
      }
      a {
        display: inline-block;
        color: #ff0852;
        margin-bottom: 0;
        &:hover {
          color: #121212;
        }
      }
    }
  }

  @media only screen and (max-width: 767.9px) {
    padding: 3.6rem 0;

    .aboutSectionChild {
      width: 100%;
    }
  }
}

.giftCard {
  padding: 1.2rem 0 4.8rem;

  .collectionHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 3.6rem 0;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.6rem;
      text-align: center;
      
      
      color: #121212;
      margin-bottom: 0.8rem;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.3rem;
      text-align: center;
      color: #121212;
    }
  }

  .giftCardChild {
    display: flex;
    align-items: center;
    margin-bottom: 8.2rem;

    img {
      width: 50%;
      height: 40rem;
      object-fit: contain;
    }
  }

  .giftCardChildRight {
    width: 41%;
    margin-left: 9%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 6.4rem;
      line-height: 7.5rem;
      
      
      color: #121212;
    }

    .subtitle {
      font-style: normal;
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.6rem;
      
      
      color: #121212;
      margin-bottom: 2.4rem;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 164.4%;
      
      color: #121212;
      opacity: 0.7;
    }
  }

  .giftBodyText {
    background: #f7f7f8;
    padding: 5rem 0;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .giftbodyTextchild {
      width: 60%;

      .title {
        border-bottom: 0.1rem solid rgba(18, 18, 18, 0.1);
        padding-bottom: 3rem;
        margin-bottom: 3.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        h5 {
          font-style: normal;
          font-weight: 600;
          font-size: 3.2rem;
          line-height: 3.8rem;
          text-align: center;
          
          
          color: #121212;
          margin-bottom: 1.6rem;
        }
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 164.4%;
        text-align: center;
        
        color: #121212;
        opacity: 0.75;
        width: 90%;
      }

      ul {
        li {
          font-style: normal;
          font-weight: 600;
          font-size: 2rem;
          line-height: 164.4%;
          
          color: #121212;
          position: relative;
          padding-left: 2.4rem;
          margin-bottom: 2.4rem;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 1rem;
            width: 1.2rem;
            height: 1.2rem;
            background: #121212;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .collectionBottom {
    .title {
      font-size: 4.8rem;
    }

    p {
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 767.9px) {
    padding: 1.2rem 0 3.2rem;

    .collectionHeader {
      margin: 0 0 2.4rem;

      h4.title {
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-bottom: 1.2rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 2.1rem;
      }
    }

    .giftCardChild {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 5.2rem;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 1.8rem;
      }

      .giftCardChildRight {
        width: 100%;
        margin-left: 0;

        h5.title {
          font-size: 3.6rem;
          line-height: 4.2rem;
          margin-bottom: 1.2rem;
        }

        h6 {
          font-size: 2.8rem;
          line-height: 3.3rem;
          margin-bottom: 1.2rem;
        }

        p {
          font-size: 1.4rem;
          line-height: 164.4%;
        }
      }
    }

    .giftBodyText {
      padding: 3.6rem 0 1.6rem;

      .giftbodyTextchild {
        width: 100%;

        .title {
          margin-bottom: 2.4rem;
          padding-bottom: 0;
          border: none;

          h5 {
            font-size: 2rem;
            line-height: 2.3rem;
            margin-bottom: 0.8rem;
          }

          p {
            font-size: 1.4rem;
            line-height: 164.4%;
            width: 100%;
          }
        }

        .giftTextUl {
          li {
            font-size: 1.4rem;
            line-height: 164.4%;
            
          }
        }
      }
    }

    .collectionBottom {
      .title {
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-bottom: 1.8rem;
      }

      p {
        margin-bottom: 2.4rem;
      }

      .blackButton {
        height: 5.2rem;
      }
    }
  }
}

.support {
  padding: 1.2rem 0 6.4rem;
  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 2.4rem;
    line-height: 2.8rem;
    
    
    color: #000000;
    margin-bottom: 2.4rem;
  }
  h2.title {
    font-style: normal;
    font-weight: 800;
    font-size: 2.4rem;
    line-height: 2.8rem;
    
    
    color: #000000;
    margin: 4.4rem 0;
  }
  h3 {
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    line-height: 2.8rem;
    
    
    color: #000000;
    margin-bottom: 2rem;
  }
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 144%;
   
    color: #121212;
    margin-bottom: 1.6rem;
  }

  .supportMain {
    a {
      color: #ff0852 !important;
      display: inline;
    }
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 3.2rem;
      line-height: 3.8rem;
      
      
      color: #121212;
      margin-bottom: 0.8rem;
    }

    .supportHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 4rem;

      p {
        font-weight: 400;
        font-size: 2.4rem;
        line-height: 2.8rem;
        text-align: center;
        
        color: #121212;
        width: 70%;

        b {
          font-weight: 600;
        }
      }
      div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .supportBody {
    padding-left: 4.2rem;

    .support-contact-icons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 2.8rem;
          
          color: #000000;
        }

        svg {
          width: 6.3rem;
          height: 6.3rem;
          margin-bottom: 1.6rem;
        }
      }
    }

    .contactForm {
      margin-top: 10.2rem;

      h4 {
        text-align: center;
        margin-bottom: 2.4rem;
      }

      .contactFormInputs {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        .contactFormInputsChild {
          width: 50%;
          margin-right: 1.6rem;
          margin-bottom: 2.8rem;
        }

        .input-group.textarea {
          width: 50%;
          align-items: flex-start;
          textarea {
            border: none;
            padding: 1.7rem 3.7rem 0;
            resize: none;
            height: 19.3rem;
          }
        }

        .input-group {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .blackButton {
        width: 21.5rem;
      }

      textarea,
      input {
        background: #f2f2f2;
        border-radius: 1.5rem;
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.1rem;
        
        color: #121212;

        &::placeholder {
          color: #12121270;
        }
      }
    }

    .supportContentText {
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 160%;
        
        color: #121212;
        border-left: 0.4rem solid #000000;
        padding-left: 2.4rem;
        margin-top: 1.6rem;
        margin-bottom: 5rem;
        a {
          color: #ff0852;
          display: inline;
          margin: 0 0.4rem;
        }
      }
      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 3.8rem;
        text-align: center;
        
        
        color: #121212;
        margin-bottom: 4rem;
      }
      ul li {
        font-style: normal;
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 144%;
        color: #121212;
        margin-bottom: 3.2rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    &.orderQuestion {
      display: flex;
      align-items: center;
      justify-content: center;

      .orderQuestion {
        width: 90%;
      }
    }

    &.delivery {
      display: flex;
      align-items: center;
      justify-content: center;

      .orderQuestion {
        width: 80%;
      }

      .delivery-content {
        p {
          strong {
            font-style: normal;
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 144%;
            color: #121212;
            padding-left: 0;
            margin-bottom: 2rem;
            display: block;
            &::after {
              display: none;
            }
          }
        }
        ul {
          li {
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 155%;
            
            color: #121212;
            opacity: 0.8;
            margin-bottom: 4rem;
            display: flex;
            flex-direction: column;
            span {
              font-style: normal;
              font-weight: 700;
              font-size: 2rem;
              line-height: 155%;
              color: #121212;
            }
            strong {
              font-style: normal;
              font-weight: 600;
              font-size: 2rem;
              line-height: 155%;
              
              color: #121212;
              margin-bottom: 0.8rem;
              display: block;
            }
          }
        }
        strong {
          position: relative;
          // padding-left: 2rem;
          margin-bottom: 1rem;

          &::after {
            content: "";
            position: absolute;
            left: -2rem;
            top: 0.7rem;
            background-color: #ff0852;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    .supportContent {
      width: 80%;

      h4,
      h3 {
        margin-bottom: 1.8rem;
      }
      &.payment {
        img {
          border-radius: 1.5rem;
          width: -webkit-fill-available;
          height: auto;
          object-fit: contain;
        }
        h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 3.2rem;
          line-height: 3.8rem;
          
          
          color: #121212;
          margin-bottom: 0.8rem;
        }
        ol,
        ul {
          padding-left: 0;
          margin-bottom: 1.4rem;
          li {
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 155%;
            
            color: #121212;
            padding-left: 2.4rem;
            position: relative;
            margin-bottom: 1.6rem;

            &::marker {
              display: none;
            }
            &::after {
              content: "";
              background: #ff0852;
              position: absolute;
              left: 0;
              top: 1rem;
              width: 1rem;
              height: 1rem;
              border-radius: 50%;
            }
            strong {
              font-weight: 600;
            }
          }
        }
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 2rem;
          line-height: 155%;
          margin-bottom: 2.4rem;
          color: #121212;
          opacity: 0.8;
        }
      }
      .supportContentp {
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 155%;
        
        color: #121212;
        padding-left: 2.4rem;
        position: relative;
        margin-bottom: 1.6rem;

        &::after {
          content: "";
          background: #ff0852;
          position: absolute;
          left: 0;
          top: 1rem;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
        }
      }

      .supportContentTextp {
        font-style: normal;
        font-weight: 400;
        font-size: 2rem;
        line-height: 155%;
        
        color: #121212;
        opacity: 0.8;
        margin-bottom: 3.2rem;
      }
    }
  }

  .supportBodyCenter {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .paymentMethod {
    .paymentMethodCard {
      width: 92%;
      margin-bottom: 7.2rem;

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 155%;
        
        color: #121212;
        margin-bottom: 2rem;
      }

      img {
        height: 52.2rem;
        width: 100%;
        border-radius: 1.5rem;
      }
    }
  }

  .discountCard-p {
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 155%;
    
    color: rgba(18, 18, 18, 0.8);
    width: 82%;

    a {
      display: inline;
      color: #ff0852;
      margin: 0 0.3rem;
    }
  }

  .supportFaq {
    width: 90%;

    .Collapsible {
      border-bottom: 0.1rem solid rgba(18, 18, 18, 0.1);

      .Collapsible__trigger {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 144%;
       
        color: #121212;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.4rem 1rem;
        cursor: pointer;

        svg {
          transition: 0.3s;
        }

        &.is-open svg {
          transform: rotate(180deg);
        }
      }

      .Collapsible__contentInner {
        padding: 0 1rem 2.4rem;
        width: 85%;

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 2rem;
          line-height: 154%;
         
          color: #121212;

          blockquote {
            margin: 0;
          }
          a {
            display: inline;
          }
        }
      }
    }
  }

  .termsContent {
    width: 90%;

    h4 {
      margin-bottom: 2rem;
    }

    p,
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 155%;
      
      color: #121212;
      opacity: 0.8;
      margin-bottom: 3.2rem;
    }

    ul {
      margin-bottom: 2.6rem;
      li,
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 155%;
        color: #121212;
      }
      li {
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 155%;
        color: #121212;
        opacity: 0.8;
        margin-bottom: 1.6rem;
      }
    }
    a {
      color: #ff0852 !important;
      display: inline;
    }
  }

  @media only screen and (max-width: 767.9px) {
    .payment {
      h3 {
        font-size: 2rem !important;
        line-height: 144% !important;
      }
      ul {
        li {
          font-size: 1.4rem !important;
          strong {
            font-size: 1.8rem !important;
          }
        }
      }
      p {
        font-size: 1.4rem !important;
        line-height: 155%;
      }
    }
    h2.title {
      margin: 1.6rem 0 2.8rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 155%;
        text-align: center;
       
        color: #121212;
        text-transform: capitalize;
        opacity: 0.36;
        margin-top: 0.4rem;
      }
    }

    .supportMain {
      padding: 3.2rem 0;

      .supportHeader {
        align-items: flex-start;
        margin-bottom: 1.4rem;
        div {
          justify-content: flex-start;
        }
        h4 {
          font-size: 1.4rem;
          line-height: 1.6rem;
          text-align: left;
        }

        p {
          display: flex;
          align-items: center;
          white-space: nowrap;
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
      }

      .supportBody {
        padding-left: 0;

        .support-contact-icons {
          flex-wrap: wrap;

          a {
            width: 50%;
            margin-bottom: 4.6rem;

            svg {
              width: 4rem;
              height: 4rem;
              margin-bottom: 1rem;
            }

            span {
              font-size: 1.6rem;
              line-height: 1.9rem;
            }
          }
        }

        .orderQuestion {
          font-size: 2rem;
          line-height: 144%;
          margin-bottom: 1.6rem;
          width: 100%;

          h4 {
            font-size: 2rem;
            line-height: 144%;
          }

          h5 {
            font-size: 2rem;
            line-height: 144%;
            margin-bottom: 2.7rem;
          }

          h6.title {
            font-size: 1.8rem;
            line-height: 155%;
          }

          .delivery-content {
            ul {
              padding-left: 2.6rem;
            }
            p {
              font-size: 1.4rem;
              line-height: 155%;
            }
            h2 {
              font-size: 2rem;
              line-height: 144%;
            }
            ul {
              li {
                font-size: 1.4rem;
                line-height: 155%;
              }
            }
          }

          .supportContentText {
            margin-bottom: 3.8rem;
            h3 {
              font-size: 2rem;
              line-height: 1.6rem;
              text-align: left;
              margin-bottom: 1.6rem;
            }
            p {
              font-size: 1.4rem;
              line-height: 160%;
              margin-bottom: 3.8rem;
            }
            li {
              margin-bottom: 1.6rem;
              strong {
                font-size: 2rem;
                line-height: 100%;
              }
            }
          }
        }

        .supportBodyCenter {
          .supportContent {
            width: 100%;

            h4 {
              font-size: 2rem;
              line-height: 144%;
              text-transform: capitalize;
            }

            .supportContentp {
              font-weight: 600;
              font-size: 1.6rem;
              line-height: 155%;
            }

            .supportContentTextp {
              font-size: 1.4rem;
              line-height: 155%;
            }
          }
        }
      }

      .contactForm {
        margin: 0;

        h4 {
          font-size: 2.4rem;
          line-height: 2.8rem;
          margin-bottom: 2.2rem;
          text-align: left;
        }

        .contactFormInputs {
          display: flex;
          flex-direction: column;

          .contactFormInputsChild {
            width: 100%;
            margin-right: 0;
            margin-bottom: 2.4rem;
          }

          input {
            font-size: 1.8rem;
            line-height: 2.1rem;
          }

          .input-group.textarea {
            width: 100%;
            margin-bottom: 2.8rem;

            textarea {
              width: -webkit-fill-available;
            }
          }
        }

        .blackButton {
          width: 100%;
          font-size: 1.7rem;
          line-height: 2rem;
          height: 5.2rem;
        }
      }
    }

    .paymentMethod {
      .paymentMethodCard {
        width: 100%;
        margin-bottom: 4rem;

        img {
          height: auto;
        }
      }

      p {
        font-size: 2rem;
        line-height: 155%;
      }
    }

    .positionRelative {
      border-right: none;
    }

    .supportSidebar {
      display: flex;
      align-items: center;
      flex-direction: row;
      border-right: none;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      a {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.6rem;
        white-space: nowrap;
        margin-bottom: 0;
        border-bottom: 0.1rem solid #d6d6d6;
        padding: 0 2rem 1.2rem;

        &.red {
          font-weight: 800;
          font-size: 1.6rem;
          line-height: 1.9rem;
          padding: 0;
          border-bottom: 0.4rem solid #121212;
          padding-bottom: 0.9rem;
        }
      }
    }

    .discountCard-p {
      font-size: 1.4rem;
      line-height: 155%;
      width: 100%;
    }

    .termsContent {
      width: 100%;

      h4 {
        font-size: 2.4rem;
        line-height: 144%;
        margin-bottom: 1.2rem;
      }

      p {
        font-size: 1.4rem;
        line-height: 155%;
        margin-bottom: 2.4rem;
      }
    }

    &.faq {
      .supportHeader {
        margin-bottom: 2rem;

        p {
          white-space: pre-wrap;
          text-align: left;
          width: 100%;
        }
      }

      .supportFaq {
        width: 100%;

        .Collapsible {
          .Collapsible__trigger {
            padding: 1.5rem 0.4rem;
            font-size: 1.8rem;
            line-height: 144%;

            svg {
              width: 3rem;
              height: 3rem;
            }
          }

          p {
            font-size: 1.6rem;
            line-height: 154%;
          }
        }
      }
    }
  }
}

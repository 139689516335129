.productCard {
  .productCardHeader {
    background: #fff;
    border-radius: 1.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37.2rem;
    overflow: hidden;
    position: relative;
    // border: .1rem solid rgba(0, 0, 0, 0.05);
    a {
      transition: 0.3s all ease;
    }
    span,img {
      object-fit: scale-down;
      width: 100%;
      height: 25rem;
    }

    .productCardBadge {
      border-radius: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1.6rem;
      left: 1.6rem;
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      text-align: center;
      
      color: #fdfcfc;
      padding: 0 1.8rem;

      // &.newProduct {
      //     background: #2FB12F;
      // }

      // &.popularProduct {
      //     background: #1562F9;
      // }

      // &.specialProduct {
      //     background: #FF0852;
      // }
    }
    .stock {
      background-color: #c9dcc9;
    }
  }

  .interest {
    position: absolute;
    left: 1.6rem;
    bottom: 1.6rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 135.9%;
    
    color: #fdfcfc;
    background: #ff0852;
    border-radius: 8rem;
    height: 2.8rem;
    padding: 0 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .productCardBody {
    padding: 1.2rem 0.2rem;

    .productCardBodyHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      .price {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 135.9%;
        
        color: #ff0852;
        display: flex;
        align-items: flex-end;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 135.9%;
          
          color: #ff0852;
          display: flex;
          align-items: center;

          svg {
            margin-top: 0.6rem;
            width: 1.2rem;
            height: 0.9rem;
          }
        }

        .discount {
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          color: #121212;
          margin-left: 1.4rem;
          position: relative;
          margin-bottom: 0.4rem;

          svg {
            margin-top: 0.4rem;
          }
        }
      }
      .blackPrice {
        color: #121212;
        span {
          color: #121212;
        }
        svg {
          path {
            stroke: #121212;
            fill: #121212;
          }
        }
      }
      .priceDiscount {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 135.9%;
        text-align: right;
        
        color: #121212;
        display: flex;
        align-items: center;
        position: relative;

        span {
          margin: 0 !important;
          color: #121212;
        }

        &::before {
          content: "";
          background: #ff0852;
          position: absolute;
          left: 0;
          width: 100%;
          height: 0.08rem;
          top: 0;
          bottom: 0;
          margin: auto;
          transform: rotate(7deg);
        }
      }

      .productCardRating {
        display: flex;
        align-items: center;
        opacity: 0.7;

        svg {
          width: 1.1rem;
          height: 1.1rem;
        }
      }
    }

    .title {
      height: 4.4rem;
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 155%;
      
      color: #121212;
      opacity: 0.9;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 0.4rem;
      white-space: pre-wrap;
    }
  }

  .productCardAddToCart {
    background: #ffffff;
    border-radius: 3rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.6rem;
    
    
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 4rem;
    transition: 0.4s;
    bottom: -5rem;
    cursor: pointer;
    // width: -webkit-fill-available;
    // margin: 0 1.3rem;
    border: none;
    width: 94%;

    svg {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 1rem;

      path {
        transition: 0.4s;
      }
    }

    &:hover {
      background: #dde7dd;
    }

    &:focus {
      background: #c9dcc9;
    }

    &:disabled {
      background: #fff;
      color: rgba(0, 0, 0, 0.35);
      pointer-events: none;

      svg {
        path {
          stroke: rgba(0, 0, 0, 0.35);
        }
      }
    }
  }
  .hoverEffectSpan {
    // display: none;
    transition: 0.4s;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.2rem;
    
    color: #000000;
    border: 0.1rem solid #121212;
    position: absolute;
    padding: 0.6rem 1rem;
    width: -webkit-fill-available;
    margin: 0 4rem;
    white-space: break-spaces;
    text-align: center;
    background: #fff;
    opacity: 0;
    bottom: -8rem;
    text-transform: capitalize;
  }

  &:hover {
    .productCardAddToCart {
      bottom: 1.2rem;
    }
    .productCardHeader {
      a {
        transform: scale(1.1);
      }
    }
    .hoverEffectSpan {
      display: block;
      bottom: 6rem;
      opacity: 0.8;
    }
  }

  .mobileAddToCart {
    display: none;
  }

  @media only screen and (max-width: 767.9px) {
    .productCardBodyHeader {
      display: flex;
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: flex-start;

      .price {
        display: flex;
        align-items: flex-start !important;
      }
    }

    .productCardBody {
      .title {
        margin-bottom: 0 !important;
      }
    }

    .productCardRating {
      // margin-top: 0.6rem;

      svg {
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0.1rem;
      }
    }

    .mobileproductCardBOdy {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .mobileAddToCart {
      display: none;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.4rem;
      
      
      color: #000000;
      border: 0.08rem solid #ff0852;
      box-sizing: border-box;
      border-radius: 3rem;
      background-color: #fff;
      height: 3.7rem;
      width: 100%;
      margin-top: 1.2rem;
      transition: 0.3s;

      svg {
        margin-right: 0.8rem;

        path {
          stroke: #ff0852;
          transition: 0.3s;
        }
      }

      &:hover {
        background-color: #ff0852;
        color: #fdfcfc;

        svg {
          path {
            stroke: #fff;
          }
        }
      }

      &:focus {
        background: #310713;
        color: #fdfcfc;
        border: 0.08rem solid #310713;

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}
.popupCart {
  .sizeCard {
    height: 7.2rem !important;
  }
}

.newsSection {
    padding-top: 5.8rem;
    margin-bottom: 9.3rem;

    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 4.2rem;
        text-align: center;
        
        
        color: #121212;
        margin-bottom: 2.8rem;
    }

    @media only screen and (max-width: 767.9px) {
        margin-bottom: 0;

        .title {
            font-size: 2.4rem;
            line-height: 2.8rem;
            margin-bottom: 1.8rem;
        }

        .swiper {
            margin-bottom: 5.4rem;
        }
    }
}
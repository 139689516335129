.headerTop {
  background-color: #fdfcfc;
  border-bottom: 0.08rem solid rgba(18, 18, 18, 0.15);
  padding: 1.2rem 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      display: block;
      margin-right: 1.4rem;

      svg {
        width: 1.4rem;
        height: 1.4rem;
        object-fit: contain;
        path {
          transition: 0.3s;
        }
      }
      &:hover {
        svg {
          path {
            stroke: #ff0852;
          }
        }
        &:nth-child(3) {
          svg {
            path {
              fill: #ff0852 !important;
              stroke: transparent;
            }
          }
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;

      color: rgba(18, 18, 18, 0.7);
      padding: 0;
      padding-left: 1.2rem;

      background-color: transparent;
      border: none;
      transition: 0.3s;
      &:first-child {
        border-right: 0.04rem solid rgba(18, 18, 18, 0.25);
        padding-right: 1.2rem;
        padding-left: 0;
      }
      &:hover {
        color: #ff0852;
      }
    }
  }

  @media only screen and (max-width: 767.9px) {
    display: none;
  }
}

.aboutPage {
  padding: 1.2rem 0 7.2rem;
  background-color: #fdfcfc;

  .aboutPageContent {
    padding-top: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 4.8rem;
      line-height: 5.6rem;
      text-align: center;
      
      
      color: #121212;
      margin-bottom: 6.4rem;
    }

    .subtitle {
      font-style: normal;
      font-weight: 600;
      font-size: 3.2rem;
      line-height: 3.8rem;
      
      
      color: #121212;
      border-bottom: 0.25rem solid #121212;
      padding-bottom: 2rem;
      margin-bottom: 2.4rem;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 3.3rem;
      
      font-feature-settings: "pnum" on, "lnum" on, "liga" off, "kern" off;
      color: #121212;
      width: 56%;
    }

    img {
      height: 66.8rem;
      border-radius: 3rem;
      width: 100%;
      margin-top: 4rem;
      object-fit: cover;
      margin-bottom: 4.8rem;
    }
  }

  .about-card-section {
    display: flex;
    flex-direction: column;

    .about-card {
      border: 0.1rem solid #d6d6d6;
      box-sizing: border-box;
      border-radius: 1.5rem;
      padding: 7rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 1rem;
      div {
        width: 60%;
      }
      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 3.8rem;
        text-align: center;
        
        
        color: #121212;
        margin-bottom: 1.6rem;
      }

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 164.4%;
        
        font-feature-settings: "pnum" on, "lnum" on, "liga" off, "kern" off;
        color: #121212;
      }
      &:last-child {
        display: none;
      }
    }

    .about-card-black {
      background: #000000;
      box-shadow: 0 2rem 2rem rgba(204, 204, 204, 0.15);
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 6rem 0;
      margin-bottom: 10rem;
      position: relative;
      display: none;
      svg.dot1 {
        position: absolute;
        left: 6.4rem;
        top: 2.4rem;
        transform: rotate(180deg);
      }

      svg.dot2 {
        position: absolute;
        right: 6.4rem;
        bottom: 1rem;
      }

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 3.8rem;
        text-align: center;
        
        
        color: #fdfcfc;
        margin-bottom: 2.4rem;
      }
      div {
        width: 60%;
      }
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 164.4%;
        
        color: #fdfcfc;
      }
      &:last-child {
        display: flex;
      }
    }
  }

  h5.title {
    font-style: normal;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.8rem;
    
    
    color: #121212;
    text-align: center;
    margin-bottom: 3.2rem;
  }

  .certifcate-card {
    // background: #fefefe;
    // box-shadow: 0 0.8rem 2rem rgba(196, 196, 196, 0.2);
    padding: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3rem;
    background: #fefefe;
    box-shadow: 0px 8px 20px rgba(196, 196, 196, 0.2);
    img {
      height: 34.8rem;
      width: 25.6rem;
      margin-bottom: 1.6rem;
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 155%;
      text-align: center;
      
      font-feature-settings: "pnum" on, "lnum" on;
      color: #121212;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 5rem;
    }
  }

  @media only screen and (max-width: 767.9px) {
    .aboutPageContent {
      padding-top: 0.8rem;
      h1 {
        font-size: 2.4rem;
        margin-bottom: 4rem;
        line-height: 2.8rem;
      }
      h5 {
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-bottom: 4rem;
      }

      .subtitle {
        font-size: 2rem;
        line-height: 2.3rem;
        padding: 0 1rem 1.2rem;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.4rem;
        line-height: 164.4%;
        width: 100%;
      }

      img {
        height: 20rem;
        margin-bottom: 4rem;
      }
    }

    .about-card-section {
      .about-card {
        padding: 4.4rem 1.7rem;
        margin-bottom: 3.2rem;

        h6 {
          font-size: 2.4rem;
          line-height: 2.8rem;
        }
        div {
          width: 100%;
        }
        p {
          font-size: 1.4rem;
          line-height: 164.4%;
          width: 100%;
        }
      }

      .about-card-black {
        padding: 2.4rem 1.7rem 2.8rem 4rem;
        margin-bottom: 5.6rem;
        div {
          width: 100%;
        }
        h5 {
          font-size: 1.8rem;
          line-height: 2.1rem;
        }

        .dot1 {
          width: 1.8rem;
          height: 2.5rem;
          top: 0.8rem !important;
          left: 1.2rem !important;
        }

        .dot2 {
          right: 1.2rem !important;
          bottom: 1rem !important;
          width: 1.8rem;
          height: 2.5rem;
        }

        p {
          font-size: 1.4rem;
          line-height: 164.4%;
          width: 100%;
        }
      }
    }

    h5.title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 2.4rem;
    }

    // .certifcate-card{

    // }
    .certifacate-row.row {
      display: flex;
      overflow: auto;
      padding: 0 2.4rem;
    }
  }
}
